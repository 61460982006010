import {
  Entity,
  BuilderApplyItem,
  Designer,
  Mesh,
  EntityRay,
} from "./designer";
import { FloorBuilder } from "./floorplanner";
import { vec3, Contour, eps, glMatrix, Box, mat4 } from "./geometry";
import { ModelHandler } from "./model-handler";
import { pb } from "./pb/scene";
import { Observable } from "rxjs";
import { CollisionHandler } from "./collision-handler";
import { ContainerManager } from "./container";
import { CatalogMaterial, createMaterial } from "app/shared/catalog.service";
import { SunLight } from "./render/render-scene";

export class CopyParams {
  count = 3;
  dir = 0;
  step = 500;
}

export interface Bookmark {
  id: string;
  name: string;
}

export class NewProjectSettings {
  extend(params?: Partial<NewProjectSettings>): this {
    return Object.assign(this, params);
  }

  $name = "materials";
  // materials
  wall = createMaterial("#FFC78E", -1);
  floor = createMaterial("#935116", -1);
  ceiling = createMaterial("#D7DBDD", -1);
  roof?: CatalogMaterial;
  // size
  length = 5000;
  width = 4000;
  height = 2500;
  thickness = 100;
  // light
  light?: any; // FileItem to insert
  headLightPower?: number;
  sun = new SunLight();
}

export class ProjectSelectionStatus {
  selected: Entity;
  symmetry = false;
  rotate = false;
  swapWallSide = false;
  copy = false;
  replace = false;
  multipleCopy = false;
  delete = false;
  paint = false;
  addWalls = false;
  splitWall = false;
  hasAux = false;
  remove = false;
  animations = false;
  properties = false;
  model = false;
  allModels = false;
  offers = false;
}

// handles ds <-> builder communications for projects
export class ProjectHandler extends ModelHandler {
  get floors(): Entity[] {
    let root = this.ds.root;
    if (root && root.children) {
      return root.children.filter((e) => !!e.data.floor);
    }
    return [];
  }

  get selectedFloorPlan(): Entity {
    if (!this.ds || !this.ds.root) {
      return undefined;
    }
    let isFloor = (e) => !!e.data.floor;
    let e = this.ds.selected;
    while (e && !isFloor(e)) {
      e = e.parent;
    }
    return e;
  }

  findFloorPlan(): Entity {
    if (!this.ds || !this.ds.root) {
      return undefined;
    }
    let isFloor = (e) => !!e.data.floor;
    let e = this.ds.selected;
    while (e && !isFloor(e)) {
      e = e.parent;
    }
    if (!e) {
      e = this.ds.root.children.find((e) => !!e.data.floor);
    }
    this.ds.selected = e;
    return e;
  }

  hasAux(item: Entity) {
    if (!item) {
      return false;
    }
    if (!item.data.floor) {
      item = item.parent;
    }
    return item && item.data.floor && item.data.floor.aux;
  }

  removeAuxLines(floor: Entity) {
    let builder = new FloorBuilder(floor);
    builder.init();
    builder.map.items = builder.map.items.filter((e) => !e.line || !e.line.aux);
    builder.updateMap(builder.map);
    let command = builder.buildFloor();
    this.ds.apply("Remove aux lines", command);
  }

  addFloor() {
    let floors = this.floors;
    if (floors.length > 0) {
      this.ds.selected = floors[floors.length - 1];
      this.copySelection();
    }
  }

  static findRoom(wall: Entity, mesh?: Mesh) {
    if (mesh && mesh.position.length === 12) {
      let vertices = mesh.position;
      let x = (vertices[0] + vertices[6]) * 0.5;
      let y = Math.min(vertices[1], vertices[7]) + 1;
      let z = (vertices[2] + vertices[8]) * 0.5;
      let dir = new Float64Array(mesh.normal.slice(0, 3));
      let pos = vec3.fromValues(x - dir[0] * 0.5, y, z - dir[2] * 0.5);
      let ray = new EntityRay();
      ray.pos = wall.toParent(pos);
      ray.dir = wall.NtoParent(dir);
      ray.distance = 1;
      return wall.parent.children.find(
        (child) => child.data.room && child.intersect(ray)
      );
    } else {
      // test wall segments id generated by splitContour
      let wallId1 = wall.data.wall.id.toString();
      let wallId2 = (wall.data.wall.id + 1).toString();
      let wallId3 = (wall.data.wall.id + 1).toString();
      return wall.parent.children.find((item) => {
        let room = item.data.room;
        return (
          room &&
          (room.id.includes(wallId1) ||
            room.id.includes(wallId2) ||
            room.id.includes(wallId3))
        );
      });
    }
  }

  static findRoomWallMeshes(room: Entity) {
    let meshes: { e: Entity; mesh: Mesh }[] = [];
    let walls = room.parent.children.filter((c) => !!c.data.wall);
    for (let roomMesh of room.meshes) {
      let dir = new Float64Array(roomMesh.normal.slice(0, 3));
      if (!glMatrix.equalsd(dir[2], 0)) {
        continue;
      }
      dir = room.NtoParent(dir);
      vec3.negate(dir, dir);
      for (let wall of walls) {
        let localDir = wall.NfromParent(dir);
        for (let wallMesh of wall.meshes) {
          let meshNormal = new Float64Array(wallMesh.normal.slice(0, 3));
          if (vec3.equals(meshNormal, localDir)) {
            if (this.findRoom(wall, wallMesh) === room) {
              meshes.push({ e: wall, mesh: wallMesh });
            }
          }
        }
      }
    }
    return meshes;
  }

  removeFloor(floor: Entity) {
    let commands: BuilderApplyItem[] = [{ uid: floor, remove: true }];
    for (let item of this.floors) {
      if (item.translation[1] > floor.translation[1]) {
        item.translate(vec3.fromValues(0, -floor.box.extent[1], 0));
        commands.push({ uid: item, matrix: item.matrix });
      }
    }
    floor.delete();
    this.ds.applyBatch("Remove floor", commands);
  }

  removeSelection() {
    let roomElems: Entity[] = [];
    let commands = [];
    let items = this.ds.selection.items.slice();
    for (let item of items) {
      if (item.data.wall || item.data.room) {
        roomElems.push(item);
      } else {
        commands.push({ uid: item, remove: true });
      }
    }
    if (roomElems.length > 0) {
      let floor = new FloorBuilder(roomElems[0].parent);
      floor.init();
      for (let elem of roomElems) {
        if (elem.data.wall) {
          floor.removeWall(elem.data.wall.id);
        } else if (elem.data.room) {
          if (floor.rooms.length > 1) {
            floor.removeRoom(elem.data.room.id);
          }
        }
        floor.updateMap(floor.map);
      }
      commands = [...commands, floor.buildFloor()];
    }
    this.ds.applyBatch("Remove selection", commands);
  }

  async copySelection() {
    let selection = this.ds.selection.items;
    if (selection.length < 1) return;

    let pivot = selection[0];
    if (pivot.data.wall || pivot.data.room) {
      pivot = pivot.parent;
      this.ds.selected = pivot;
    }

    let dir = vec3.fsub(
      pivot.parent.box.center,
      pivot.toParent(pivot.box.center)
    );
    dir[1] = 0;
    dir = vec3.normalize(dir, dir) || vec3.fromValues(1, 0, 0);
    let extent = pivot.box.extent;
    let length = Math.sqrt(extent[0] * extent[0] + extent[2] * extent[2]) * 0.5;
    dir[0] = dir[0] * length + 100;
    dir[1] = 0;
    dir[2] = dir[2] * length + 100;

    let pivotPos = pivot.elastic?.position;
    let Position = pb.Elastic.Position;
    let isSplitter =
      pivotPos >= Position.Vertical && pivotPos <= Position.FSplitter;

    if (isSplitter) {
      if (pivotPos === Position.VSplitter || pivotPos === Position.Vertical) {
        dir = vec3.fromValues(extent[0], 0, 0);
      }
      if (pivotPos === Position.HSplitter || pivotPos === Position.Horizontal) {
        dir = vec3.fromValues(0, extent[1], 0);
      }
      if (pivotPos === Position.FSplitter || pivotPos === Position.Frontal) {
        dir = vec3.fromValues(0, 0, extent[2]);
      }
    } else if (pivot.data.floor) {
      dir = vec3.fromValues(0, pivot.box.extent[1], 0);
    } else if (pivot.parent.data.wall) {
      dir[2] = 0;
    } else if (this.ds.selected) {
      let cd = new ProjectCollisionHandler(this.ds, true);
      let selected = this.ds.selected;
      let sizex = selected.box.sizex;
      if (cd.moveDistance(selected.NtoGlobal(vec3.axis_z), 1) < 5.0) {
        let dist1 = cd.moveDistance(
          selected.NtoGlobal(vec3.axis_x),
          sizex,
          sizex
        );
        let dist2 = cd.moveDistance(
          selected.NtoGlobal(vec3.axisx),
          sizex,
          sizex
        );
        if (dist1 > dist2 && dist1 > sizex - eps) {
          dir = selected.NtoGlobal(vec3.fromValues(-sizex, 0, 0));
        } else if (dist2 > sizex - eps) {
          dir = selected.NtoGlobal(vec3.fromValues(sizex, 0, 0));
        }
      }
    }
    let action = { dx: dir[0], dy: dir[1], dz: dir[2] };
    let newSelection = await this.ds.executeActionOnSelection(
      "copy",
      "Copy",
      undefined,
      action
    );
    this.ds.selection.clear();
    let ok = false;
    for (let id of newSelection) {
      let e = this.ds.entityMap[id];
      if (e) {
        e.selected = true;
        ok = true;
      }
    }
    return ok;
  }

  private selectItems(root: Entity) {
    let selectable = (e: Entity) => !!(e.data.model && e.data.model.id);
    if (root.children) {
      for (let item of root.children) {
        if (selectable(item)) {
          item.selected = true;
        } else {
          this.selectItems(item);
        }
      }
    }
  }

  selectAll() {
    this.ds.selection.clear();
    this.selectItems(this.ds.root);
  }

  linkQueryParams(back = false) {
    if (back) {
      return {
        camera: this.ds.saveCamera(),
      };
    } else {
      return {
        root: this.ds.selected.uidStr,
        camera: this.ds.saveCamera(this.ds.selected),
      };
    }
  }

  advancedCopy(params: CopyParams) {
    let item = this.ds.selected;
    let container = item.parent;
    let shift = vec3.create();
    let axis = this.getElasticAxis(item);
    let oldMatrix = item.matrix.slice();
    let itemBox = item.sizeBox;
    if (params.dir) {
      shift[axis] = (params.step + itemBox.extent[axis]) * params.dir;
    } else {
      let box = ContainerManager.containerFreeBox(container);
      let step =
        (box.extent[axis] - itemBox.extent[axis] * params.count) /
        (params.count + 1);
      let itemParentBox = itemBox.copy().transform(item.matrix);
      shift[axis] = box.min[axis] - itemParentBox.min[axis] + step;
      item.translate(shift);
      shift[axis] = step + itemBox.extent[axis];
    }

    let actions: BuilderApplyItem[] = [];
    for (let index = 0; index < params.count; ++index) {
      actions.push({ uid: item, copy: index > 0, matrix: item.matrix.slice() });
      item.translate(shift);
    }
    item.matrix = oldMatrix;
    this.ds.applyBatch("Copy", actions);
  }

  static createNewProject(projectParams?: NewProjectSettings) {
    let params = new NewProjectSettings().extend(projectParams);
    let floor = new FloorBuilder(undefined);
    floor.wallThicknes = params.thickness;
    floor.wallHeight = params.height;
    floor.setWallMaterial(params.wall.name, params.wall.catalogId);
    floor.setFloorMaterial(params.floor.name, params.floor.catalogId);
    floor.setCeilingMaterial(params.ceiling.name, params.ceiling.catalogId);
    let map = new Contour();
    let halfThickness = floor.wallThicknes * 0.5;
    let length = params.length;
    let width = params.width;
    map.addRectxy(
      -halfThickness,
      -halfThickness,
      length + halfThickness,
      width + halfThickness
    );
    floor.init(map);
    let action = floor.buildFloor();
    if (params.light) {
      action.insert = {
        insertModelId: params.light.id,
        modelName: params.light.name,
        matrix: mat4.ffromTranslation(
          length * 0.5,
          floor.wallHeight,
          width * 0.5
        ),
      };
    }
    let items = [Designer.normalizeBuilderItem(action)];
    items.push({
      uid: "root",
      data: {
        headLight: {
          power: params.headLightPower,
          enabled: true,
        },
        sunLight: params.sun || new SunLight(undefined),
      },
    });

    return { type: "apply", undo: "clear", items };
  }

  bookmarks$() {
    return new Observable<Bookmark[]>((s) => {
      this.ds.execute({ type: "get-key", key: "bookmarks" }).then((result) => {
        let list = JSON.parse(result || null) || [];
        setTimeout((_) => s.next(list));
      });
    });
  }

  setBookMarks(list: Bookmark[]) {
    console.log(JSON.stringify(list));
    return this.ds.execute({
      type: "set-key",
      key: "bookmarks",
      value: JSON.stringify(list),
    });
  }

  status() {
    const Position = pb.Elastic.Position;
    let result = new ProjectSelectionStatus();
    let s = this.ds.selected;
    let selectedItems = this.ds.selectedItems;
    result.selected = s;
    let plannerElem = false;
    result.remove = !selectedItems.some(
      (e) => e.data.wall || e.data.room || e.data.ceiling
    );
    if (s) {
      plannerElem = !!s.data.wall || !!s.data.room || !!s.data.ceiling;
      let parent = s.parent;
      if (s.data.wall) {
        result.remove = true;
      } else if (s.data.room) {
        result.remove = s.parent.children.some((e) => e !== s && !!e.data.room);
      }
      result.symmetry =
        !plannerElem && s.data.symmetry && parent && !parent.data.symmetry;
      result.swapWallSide = parent && !!parent.data.wall;
      result.paint = plannerElem && this.ds.render.isShadedMode;
      result.addWalls = !!s.data.wall || !!s.data.room;
      result.splitWall = !!s.data.wall;
      result.hasAux = this.hasAux(s);

      let containerItem = s.isContainerItem;
      let itemPos = s.elastic && s.elastic.position;
      result.multipleCopy =
        containerItem &&
        itemPos >= Position.Vertical &&
        itemPos <= Position.FSplitter;
      result.rotate =
        !plannerElem && !containerItem && parent && !parent.data.wall;
      result.model = !!s.data.model;
      result.offers =
        result.model && !!s.data.model.offers && s.data.model.offers.length > 0;
    } else {
      result.rotate = selectedItems.every(
        (e) => !(e.elastic && e.elastic.position)
      );
    }
    result.copy = !plannerElem;
    if (result.copy) {
      for (let s of selectedItems) {
        if (
          s.isContainerItem &&
          s.elastic.position === Position.Fill &&
          s.parent.elastic.position === Position.Fill
        ) {
          result.copy = false;
        }
      }
    }
    result.replace = !plannerElem;
    result.allModels = selectedItems.every((item) => !!item.data.model);

    selectedItems.forEach((s) =>
      s.forAll((e) => {
        if (e.anim) {
          result.animations = true;
        }
        if (
          (e.data.propInfo && e.data.propInfo.props) ||
          (e.elastic && e.elastic.params)
        ) {
          result.properties = true;
        }
      })
    );
    if (plannerElem) {
      result.animations = false;
    }

    return result;
  }
}

export class ProjectCollisionHandler extends CollisionHandler {
  constructor(private ds: Designer, init?: boolean, private item?: Entity) {
    super();
    if (init) {
      this.update();
    }
  }

  public update() {
    this.clear();
    this.addCollisionObjects(this.ds.root);
  }

  private computeBox(root: Entity) {
    let box = new Box();
    box.clear();
    if (root.contentBox) box.addBox(root.contentBox);
    if (root.elastic && root.elastic.box) {
      box.addBox(root.elastic.box);
    }
    if (root.children) {
      for (let k = 0; k < root.children.length; k++) {
        let child = root.children[k];
        let disabled = child.data.collision && child.data.collision.disabled;
        if (!disabled) {
          box.addOBB(this.computeBox(child), child.matrix);
        }
      }
    }
    return box;
  }

  addCollisionObjects(root: Entity) {
    for (let k = 0; k < root.children.length; k++) {
      let child = root.children[k];
      let visible = child.isVisible;
      if (
        child.visibleDir &&
        child.renderLink &&
        child.renderLink.hidden === true
      ) {
        visible = false;
      }
      if (this.item ? child === this.item : child.selected) {
        let childBox = this.computeBox(child);
        this.addObject(
          child,
          childBox,
          child.actualGlobalMatrix,
          false,
          visible
        );
      } else {
        let collision = child.data.collision;
        let disabled = collision && collision.disabled;
        if (!disabled) {
          if (child.contentBox) {
            let box = child.contentBox;
            this.addObject(child, box, child.actualGlobalMatrix, true, visible);
          }
          if (child.children) {
            this.addCollisionObjects(child);
          }
        }
      }
    }
  }
}
