import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ClientInfo, OrderService } from 'app/shared';
import { ITdDynamicElementConfig } from 'app/shared/dynamic-forms/services/dynamic-forms.service';
import { SystemService, OrderSettings } from 'app/shared/system.service';
import { HttpErrorResponse } from '@angular/common/http';

enum PhoneStatus {
  Unknown,
  Processing,
  Confirmed
}
@Component({
  selector: 'app-client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.scss']
})
export class ClientEditorComponent implements OnInit {

  constructor(private firm: OrderService, private system: SystemService) { }

  @Input() createMode = false;

  private _value: ClientInfo;

  @Input()
  set client(value: ClientInfo) {
    this._value = value;
    if (value) {
      this.form.patchValue(value);
    }
  }

  get value() {
    return this._value;
  }

  @ViewChild('smsInput') smsInput: ElementRef;

  get client() {
    return this.form.value as ClientInfo;
  }

  orderSettings = new OrderSettings();

  name = new FormControl('', [Validators.required]);
  email = new FormControl();
  phone = new FormControl();
  address = new FormControl();
  params = new FormGroup({});
  form = new FormGroup({
    name: this.name,
    email: this.email,
    phone: this.phone,
    address: this.address,
    params: this.params
  });

  smsCode = new FormControl('');

  phoneVerification = false;
  sendingCode = false;
  phoneRequired = false;

  phoneStatus: PhoneStatus = 0;
  PhoneStatus = PhoneStatus;

  lastClient: ClientInfo;

  paramFormElements: ITdDynamicElementConfig[] = [];

  ngOnInit() {
    this.system.getSettings(OrderSettings).subscribe(s => {
      this.orderSettings = s;
      this.paramFormElements = s.customClientParams || [];
      this.phoneVerification = s.verifyPhone;
      this.phoneRequired = s.requirePhone;
      if (this.phoneRequired) {
        this.phone.setValidators(Validators.required);
        this.phone.updateValueAndValidity();
      }
    });
    if (this.createMode) {
      let data = localStorage.getItem('ClientEditorComponent');
      if (data) {
        let info = JSON.parse(data);
        if (info) {
          this.lastClient = info.client;
        }
      }
    } else if (this.client && this.client.phone) {
      this.phoneStatus = PhoneStatus.Confirmed;
    }
    this.phone.valueChanges.subscribe(value => {
      if (this.phone.hasError('incorrectPhone')) {
        this.phone.setErrors({ 'incorrectPhone': null });
      }
      if (this.phone.hasError('smsServerError')) {
        this.phone.setErrors({ 'smsServerError': null });
      }
      if (this.phoneVerification && !!value && !(this.phoneStatus === PhoneStatus.Confirmed)) {
        this.phone.setErrors({ 'needsToConfirm': true });
      }
    })
  }

  useLastClient() {
    this.form.patchValue(this.lastClient);
    this.lastClient = undefined;
  }

  saveOrderInfo(id: number, params?: any) {
    let client = this.form.value;
    localStorage.setItem('ClientEditorComponent', JSON.stringify({ client }));
    return this.firm.setOrder(id, { id, status: '', params, client });
  }

  verifyPhoneNumber() {
    this.phoneStatus = PhoneStatus.Processing;
    this.sendingCode = true;
    this.system.sendSmsCode(this.phone.value).subscribe(
      phone => {
        this.phone.setValue(phone);
        this.sendingCode = false;
        setTimeout(() => {
          this.smsInput.nativeElement.value = null;
          this.smsInput.nativeElement.focus();
        }, 100);
      },
      error => {
        if (error instanceof HttpErrorResponse && error.status >= 500) {
          this.phone.setErrors({ 'smsServerError': true });
        } else {
          this.phone.setErrors({ 'incorrectPhone': true });
        }
        this.phoneStatus = PhoneStatus.Unknown;
      }
    );
  }

  verifyKey(code: string) {
    this.sendingCode = true;
    this.system.verifySmsCode(this.phone.value, code).subscribe(ok => {
      this.sendingCode = false;
      if (ok) {
        this.smsCode.reset();
        this.phoneStatus = PhoneStatus.Confirmed;
        this.phone.setErrors(null);
      } else {
        setTimeout(() => {
          this.smsInput.nativeElement.focus();
          this.smsCode.setErrors({})
        }, 100);
      }
    }, _ => {
      this.sendingCode = false;
      this.smsCode.setErrors({});
    });
  }

  changePhoneNumber() {
    this.phoneStatus = PhoneStatus.Unknown;
    this.phone.setErrors({ 'needsToConfirm': true });
  }
}
