import { Component, OnInit, Output, EventEmitter, Input, Optional,
  ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { CatalogService, Catalog, FilesService, FileItem, CatalogType,
  AuthService, catalogSort, FileViewInfo, SearchQuery } from '../../shared';
import { Observable, of } from 'rxjs';
import { EstimateService } from '../../planner/estimate';
import { concatMap, map, shareReplay, startWith, tap, take, catchError, debounceTime, scan } from 'rxjs/operators';
import { SystemService } from 'app/shared/system.service';
import { FormControl } from '@angular/forms';
import { SelectOptions } from '../ui.script';
import { MatDialogRef } from '@angular/material/dialog';

enum FolderStatusCode {
  ServerError = -1,
  EmptySearch = -2,
  SearchResult = -3,
  SearchNotFound = -4
}

@Component({
  selector: 'app-model-explorer',
  templateUrl: './model-explorer.component.html',
  styleUrls: ['./model-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelExplorerComponent implements OnInit {
  constructor(
    private hostElement: ElementRef,
    private auth: AuthService,
    private system: SystemService,
    private catalogService: CatalogService,
    private filesService: FilesService,
    private cd: ChangeDetectorRef,
    @Optional() private estimate?: EstimateService,
    @Optional() public dialogRef?: MatDialogRef<ModelExplorerComponent>
  ) {}

  @Input() set customFolder(value: Observable<FileItem>) {
    if (value) {
      this.folder$ = value.pipe(startWith({ id: 0, name: '', folder: false }));
    }
  }

  catalogs$ = this.auth.isAuthenticated.pipe(
    concatMap(_ => this.catalogService.getAllCatalogs()),
    map(list => {
      list = list.filter(c => c.type !== CatalogType.Material);
      return {
        my: list.filter(c => c.ownerId === this.auth.userId).sort(catalogSort),
        all: list.filter(c => c.ownerId !== this.auth.userId).sort(catalogSort)
      }
    }),
    shareReplay()
  );

  ngOnInit() {}

  @Output() modelDrag = new EventEmitter<FileItem>();
  @Output() fileSelected = new EventEmitter<FileItem>();
  @Input() recentFolders: FileItem[];
  @Input() fileFilter?: (f: FileItem) => boolean;

  folder$?: Observable<FileViewInfo>;
  FolderStatusCode = FolderStatusCode;
  activeFileId?: number;
  searchFolderId?: number;

  @ViewChild('searchInput') searchInput?: ElementRef;

  searchTerm = new FormControl('');

  selectCatalog(c: Catalog) {
    this.selectFolder(c.modelFolderId);
  }

  activateFile(id: number) {
    this.activeFileId = id;
    this.cd.markForCheck();
  }

  fileTrackBy = (_: number, item: FileItem) => item.id;

  back() {
    if (this.searchFolderId) {
      this.searchTerm.setValue('', { emitEvent: false });
      this.selectFolder(this.searchFolderId);
      this.cd.markForCheck();
    } else if (this.folder$) {
      this.folder$.pipe(take(1)).subscribe(f => this.selectFolder(f.parentId));
    }
  }

  fileClick(f: FileItem, folder: FileItem) {
    if (f.folder) {
      this.selectFolder(f.id, f);
    } else {
      this.addToRecent(folder);
      this.fileSelected.emit(f);
      this.modelDrag.emit(f);
    }
  }

  searchFilesInCatalog(catalog: number, folderId: number, options: SelectOptions = {}) {
    let search = options.search;
    this.searchFolderId = folderId;
    setTimeout(() => this.searchInput?.nativeElement.focus(), 50);
    if (search) {
      this.searchTerm.setValue(search, { emitEvent: false });
    }
    let folder$ = this.searchTerm.valueChanges.pipe(
      debounceTime(500),
      startWith(this.searchTerm.value),
      concatMap((term: string) => {
        if (!term || !term.trim()) {
          return of({ catalogId: FolderStatusCode.EmptySearch, files: [], folder: true }) as Observable<FileViewInfo>;
        } else {
          let mode = options.mode || 'trigram';
          let folders = !(options.folders === false);
          return this.catalogService.searchCatalog({ catalog, term, mode, folder: folderId, folders }).pipe(
            map(result => {
              let search = {
                files: result.files || [],
                catalogId: FolderStatusCode.SearchResult,
                name: '',
                folder: true
              } as FileViewInfo
              if (!search.files.length) {
                search.catalogId = FolderStatusCode.SearchNotFound;
                search.name = options.notFoundMessage || '';
              }
              return search;
            }),
            startWith({ catalogId: FolderStatusCode.SearchResult, files: [], folder: false } as FileViewInfo));
        }
      }),
      scan((acc, val) => {
        if (acc?.folder && acc.files?.length > 0 && !val.folder) {
          val.files = acc.files
        }
        return val;
      })
    );
    this.displayFolder(folder$);
  }

  displayFolder(folder$: Observable<FileViewInfo>, initialData?: FileItem) {
    if (initialData) {
      initialData.folder = false;
    }
    if (this.estimate && this.estimate.canFillPrices()) {
      folder$ = folder$.pipe(concatMap(
        folder => this.estimate.fillPrices(folder.files).pipe(
          startWith(undefined),
          map(filesWithPrice => {
            if (filesWithPrice) {
              folder.files = filesWithPrice;
            }
            folder.files = folder.files.filter(f => f.folder || !f.sku || f.price > 0);
            return folder;
          })
      )));
    }
    this.folder$ = folder$.pipe(
      map(f => {
        if (f.files && this.fileFilter) {
          f.files = f.files.filter(this.fileFilter);
        }
        return f;
      }),
      tap(f => {
        let index = f.files && f.files.findIndex(f => f.id === this.activeFileId);
        if (this.activeFileId && index >= 0) {
          setTimeout(() => {
            let item = this.hostElement.nativeElement.querySelector(`div.file-list div.file-item:nth-child(${index})`);
            if (item) {
              item.scrollIntoView();
            }
          }, 250);
        }
      }),
      startWith(initialData || {
        id: -1,
        name: '',
        // loading flag
        folder: false
      }),
      shareReplay(1),
      catchError(e => {
        console.error(e);
        return of({
          id: -1,
          catalogId: FolderStatusCode.ServerError,
          name: '',
          folder: true
        });
      }),
      tap(_ => {
        this.cd.markForCheck();
      })
    );
    this.cd.markForCheck();
  }

  selectFolder(idOrName?: number | string, initialData?: FileItem) {
    this.folder$ = undefined;
    this.searchFolderId = undefined;
    if (idOrName) {
      let folder$: Observable<FileItem>
      if (typeof idOrName === 'number') {
        folder$ = this.filesService.getFile(idOrName, true).pipe(
        concatMap(f => f.folder ? of(f) : this.filesService.getFile(f.parentId, true))
      );
      } else {
        folder$ = this.catalogService.findCatalogByName(idOrName).pipe(
          concatMap(catalog => this.filesService.getFile(catalog.modelFolderId, true))
        );
      }
      this.displayFolder(folder$, initialData);
    }
    this.cd.markForCheck();
  }

  reload() {
    if (this.folder$) {
      this.folder$.pipe(take(1)).subscribe(f => this.selectFolder(f.id, f));
    }
  }

  dragDropModel(f: FileItem, folder: FileItem) {
    if (!f.folder) {
      this.addToRecent(folder);
    }
    this.modelDrag.emit(f);
    return false;
  }

  addToRecent(folder?: FileItem) {
    if (this.recentFolders && folder && folder.id > 0) {
      let index = this.recentFolders.findIndex(f => f.id === folder.id);
      if (index >= 0) {
        this.recentFolders.splice(index, 1);
      }
      this.recentFolders.splice(0, 0, folder);
      this.cd.markForCheck();
    }
  }

  fileMouseUp(event: MouseEvent, f: FileItem) {
    if (!this.system.initConfig.mode && event.button === 2) {
      let url = `/catalog/${f.catalogId}/model/${f.id}`;
      if (f.folder) {
        url = `/catalog/${f.catalogId}/folder/${f.id}`;
      }
      window.open(window.location.origin + url, '_blank');
    }
  }
}
