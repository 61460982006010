import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import { SystemService } from 'app/shared/system.service';

if (environment.production) {
  enableProdMode();
}

async function bootstrapApp() {
  await SystemService.resolveInitConfiguration();
  platformBrowserDynamic().bootstrapModule(AppModule);
}

bootstrapApp();
