import { glMatrix } from './geometry/common';
import { Box, plane, vec3 } from './geometry';
import * as geom from './geometry/geometry';
import { Entity, Designer, NavigationMode } from './designer';
import { CameraTool, MouseInfo, ToolCursor, findDrawing, ToolCancelled } from './designer-tool';
import { locatePoint } from './snap-locators';
import { ImageMaker } from 'app/planner/image-maker';
import { PointPicker } from './picker-tools';

export class MeasureTool extends CameraTool {
  private autoSizes: Entity;

  constructor(ds: Designer, imageMaker?: ImageMaker) {
    super(ds);
    this.selectionMode = false;
    this.sizeRoot = this.ds.temp.addChild();
    this._sizeEntity = this.sizeRoot.addChild();
    this._updateHint();
    this.cursor = ToolCursor.Pointer;
    if (imageMaker) {
      this.addCommand($localize`SHOW ARTICLE SIZES`, () => {
        if (this.autoSizes) {
          this.autoSizes.delete();
        }
        this.autoSizes = imageMaker.createArticleSizes();
        this.autoSizes.parent = this.sizeRoot;
      });
      this.addCommand($localize`SHOW PLAN SIZES`, () => {
        if (this.autoSizes) {
          this.autoSizes.delete();
        }
        this.autoSizes = imageMaker.createFloorSizes();
        this.autoSizes.parent = this.sizeRoot;
      });
    }
  }

  private addToModel(size: Entity, sizeElem: geom.Element) {
    if (size.drawing instanceof geom.Contour && !(sizeElem instanceof geom.Size)) {
      sizeElem = size.drawing.find(e => e instanceof geom.Size);
    }
    sizeElem = sizeElem || size.drawing;
    this.ds.apply('', {
      parent: 'root',
      name: $localize`Dimension`,
      matrix: size.globalMatrix,
      data: {
        drawing: JSON.stringify(sizeElem.save())
      }
    }).then(_ => this.ds.snack($localize`SIZE ADDED TO MODEL`));
  }

  protected finishing() {
    this.sizeRoot.delete();
    super.finishing();
  }

  private sizeRoot: Entity;
  private _sizeEntity: Entity;
  private _p1: Float64Array;
  private _p2: Float64Array;

  private _findPoint(mouse: MouseInfo) {
    let point = locatePoint(mouse, this.root, this.ds.root.windowMatrix);
    if (point) {
      return point;
    }
    let ray = this.createRay(mouse);
    if (this.intersect(ray)) {
      return ray.intersectPos;
    }
  }

  private _updateHint() {
    if ((this._p1 && this._p2) || !this._p1) {
      this.hint = $localize`Specify first point`;
    } else {
      this.hint = $localize`Specify second point`;
    }
  }

  private _updateDrawing(curPoint: Float64Array) {
    let pos = curPoint;
    let p2 = this._p2 || curPoint;
    if (this._p1) {
      if (vec3.equals(this._p1, p2)) {
        p2 = vec3.fadd(p2, vec3.axisx);
      }
      pos = vec3.fmiddle(p2, this._p1);
    }
    let size = this._sizeEntity;
    size.setIdentityTransform();
    size.translate(pos);

    if (this._p1) {
      let diry = vec3.fsub(p2, this._p1);
      vec3.normalize(diry, diry);
      let dirz = this.ds.camera.NtoGlobal(vec3.axisz);
      let dirx = vec3.fcross(diry, dirz);
      if (vec3.len(dirx) > glMatrix.EPSILON) {
        dirz = vec3.fcross(diry, dirx);
        vec3.normalize(dirz, dirz);
        size.orient(dirz, diry);
      }
    } else {
      let dirz = this.ds.camera.NtoGlobal(vec3.axisz);
      let diry = this.ds.camera.NtoGlobal(vec3.axisy);
      size.orient(dirz, diry);
    }

    let contour = new geom.Contour();

    let plocal = size.toLocal(curPoint);
    contour.addPointxy(plocal[0], plocal[1]);
    if (this._p1) {
      let plocal1 = size.toLocal(this._p1);
      let plocal2 = size.toLocal(p2);
      contour.addPointxy(plocal1[0], plocal1[1]);
      contour.addPointxy(plocal2[0], plocal2[1]);
      contour.addSizexy(plocal1[0], plocal1[1], plocal2[0], plocal2[1]);
    }
    size.drawing = contour;
    size.changed();
  }

  protected move(mouse: MouseInfo) {
    super.move(mouse);
    let curPoint = this._findPoint(mouse);
    if (curPoint && this._p1 && this.ds.camera.mode === NavigationMode.Ortho) {
      curPoint[1] = this._p1[1];
    }
    if (curPoint) {
      this._updateDrawing(curPoint);
    }
  }

  protected up(mouse: MouseInfo) {
    super.up(mouse);
    if (!this.moving) {
      if (mouse.left) {
        let curPoint = this._findPoint(mouse);
        if (curPoint) {
          if (!this._p1) {
            this._p1 = curPoint;
            this._p2 = undefined;
            this._updateDrawing(curPoint);
          } else {
            this._p2 = curPoint;
            this._updateDrawing(curPoint);
            this._p1 = undefined;
            this._p2 = undefined;
            this._sizeEntity = this.sizeRoot.addChild();
          }
          this._updateHint();
        }
      }
      if (mouse.middle) {
        let info = findDrawing(this.sizeRoot, mouse, e => e !== this._sizeEntity);
        if (info) {
          this.addToModel(info.e, info.element);
          info.e.delete();
        }
      }
    }
  }
}

export class CreateDimensionTool extends CameraTool {
  protected async starting() {
    while (true) {
      await this.newSizeSequence();
    }
  }

  private async newSizeSequence() {
    let p1 = await this.run(new PointPicker(this.ds, $localize`Specify first point`));
    try {
      let p2 = await this.run(new PointPicker(this.ds, $localize`Specify second point`, {
        orthoBasis: p1,
        feedback: (p, o) => {
          o.addLine3(p1, p);
          o.addPoint3(p1);
          o.addPoint3(p);
          let middle = vec3.fmiddle(p1, p);
          o.addText3(middle, Math.round(vec3.distance(p1, p)).toString());
        }
      }));
      let orthoAxes: Float64Array[] = [];
      let dir = vec3.fnormalize(vec3.fsub(p2, p1));
      for (let i = 0; i < 3; ++i) {
        let axis = vec3.make(0, 0, 0);
        axis[i] = 1;
        if (Math.abs(vec3.dot(dir, axis)) < 0.9) {
          orthoAxes.push(axis);
        }
      }
      let p1p2 = vec3.fsub(p2, p1);
      vec3.normalize(p1p2, p1p2);
      if (Math.abs(vec3.dot(this.ds.camera.viewDir, p1p2)) < geom.eps) {
        orthoAxes.push(vec3.fcross(this.ds.camera.viewDir, p1p2));
      }
      await this.run(new PointPicker(this.ds, $localize`Specify text position`, {
        orthoBasis: p2,
        orthoAxes,
        orthoDistance: Number.POSITIVE_INFINITY,
        feedback: (p, o) => {
          o.addPoint3(p1);
          o.addPoint3(p2);
          o.addLine3(p1, p2);
          this.makeSize(p1, p2, p);
        }
      }));
      createOrUpdateDimension(this.temp);
    } catch (e) {
      if (e instanceof ToolCancelled) {
        this.temp.drawing = null;
        this.temp.changed();
      } else {
        throw e;
      }
    }
  }

  private makeSize(p1: Float64Array, p2: Float64Array, p3: Float64Array) {
    let e = this.temp;
    e.setIdentityTransform();
    let pos = vec3.fmiddle(p1, p2);
    e.translate(pos);

    let diry = vec3.fsub(p3, p2);
    vec3.normalize(diry, diry);
    let dirx = vec3.fsub(p1, p2);
    vec3.normalize(dirx, dirx);
    let dirz = vec3.fcross(dirx, diry);
    vec3.normalize(dirz, dirz);
    e.orient(dirz, diry);

    let lp1 = geom.newVector2(e.toLocal(p1));
    let lp2 = geom.newVector2(e.toLocal(p2));
    let lp3 = geom.newVector2(e.toLocal(p3));
    let lp3_1 = geom.add(lp3, geom.newVector(1, 0));
    let dim1 = geom.pointLineProjection(lp1, lp3, lp3_1);
    let dim2 = geom.pointLineProjection(lp2, lp3, lp3_1);
    let textPos = geom.middle(dim1, dim2);
    let size = new geom.Size(lp1, lp2, textPos);
    size.dim1 = dim1;
    size.dim2 = dim2;

    let rect = size.rect;
    let box = new Box();
    box.minx = rect.min.x;
    box.miny = rect.min.y;
    box.maxx = rect.max.x;
    box.maxy = rect.max.y;
    e.elastic = { box, params: [] };

    e.drawing = size;
    e.boxChanged();
    e.changed();
  }
}

export class DimensionEditorTool extends CameraTool {
  constructor(private entity: Entity, private mode: 'p1' | 'p2' | 'text') {
    super(entity.ds);
    this.startDrag('DimensionEditorTool');
    entity.visible = false;
  }

  protected plane = plane.createPN(
    this.entity.toGlobal(vec3.origin),
    this.entity.NtoGlobal(vec3.axisz)
  );
  protected preview = this.ds.temp.addChild();

  protected move(mouse: MouseInfo) {
    let pos3d = locatePoint(mouse, this.root, this.ds.root.windowMatrix);
    if (!pos3d) {
      let ray = this.createRay(mouse);
      if (ray.intersectPlane(this.plane)) {
        pos3d = ray.intersectPos;
      }
    }
    if (pos3d) {
      let localPos = this.entity.toLocal(pos3d);
      let pos2d = new geom.Vector(localPos[0], localPos[1]);
      this.updateDrawing(pos2d);
    }
    super.move(mouse);
  }

  protected updateDrawing(activePoint: geom.Vector) {
    let contour = new geom.Contour();
    this.preview.drawing = contour;
    this.preview.matrix = this.entity.globalMatrix;
    this.preview.drawing = geom.Element.create(this.entity.data.drawing);
    if (this.preview.drawing instanceof geom.Size) {
      let size = this.preview.drawing;
      let p1 = size.p1.clone();
      let p2 = size.p2.clone();
      let text = size.textPos.clone();
      switch (this.mode) {
        case 'p1':
          p1 = geom.pointLineProjection(activePoint, p1, p2);
          break;
        case 'p2':
          p2 = geom.pointLineProjection(activePoint, p1, p2);
          break;
        case 'text':
          text = activePoint;
          break;
      }
      if (this.mode !== 'text') {
        text = geom.pointLineProjection(geom.middle(p1, p2), size.dim1, size.dim2);
      }
      size.init(p1, p2, text);
    }
    this.preview.changed();
    this.invalidate();
  }

  protected up(mouse: MouseInfo) {
    this.entity.visible = true;
    if (mouse.left) {
      createOrUpdateDimension(this.entity, this.preview.drawing);
    }
    this.finish();
  }

  protected finishing() {
    if (this.preview) {
      this.preview.delete();
      this.preview = undefined;
    }
    super.finishing();
  }
}


export function createOrUpdateDimension(dimension: Entity, drawing?: geom.Element) {
  if (drawing) {
    dimension.drawing = drawing;
    dimension.changed();
  }
  let command = dimension.uid ? { uid: dimension.uidStr } : { parent: dimension.ds.root };
  drawing = drawing || dimension.drawing;
  let rect = drawing.rect;
  dimension.ds.apply('New size', {
    ...command,
    matrix: dimension.globalMatrix,
    data: {
      drawing: JSON.stringify(drawing.save())
    },
    elastic: {
      box: [...rect.min.arr, 0, ...rect.max.arr, 0]
    }
  });
}
