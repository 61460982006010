import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  MaterialUnit,
  FileItem,
  OrderInfo,
  AuthService,
  AccountService,
  FileOrderItem,
} from "../../shared";
import { EstimateService } from "../estimate";
import { FloorProjectStatistics } from "modeler/floorplanner";
import { Observable } from "rxjs";
import { TdFileService } from "app/shared/file/services/file.service";
import { map } from "rxjs/operators";
import { OrderSettings, SystemService } from "app/shared/system.service";
import { ITdDynamicElementConfig } from "app/shared/dynamic-forms/services/dynamic-forms.service";
import { FormGroup } from "@angular/forms";

export interface ProjectDetails {
  file: FileOrderItem;
  revision: string;
  tab: number;
  shared?: string;
  readOnly: boolean;
  estimate: EstimateService;
  backups: Observable<FileItem[]>;
  order: Observable<OrderInfo>;
  statistics: FloorProjectStatistics;
  print: () => void;
  scriptInterface: any;
}

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
})
export class ProjectDetailsComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectDetails,
    public system: SystemService,
    public auth: AuthService,
    public files: TdFileService,
    private dialogRef: MatDialogRef<ProjectDetailsComponent>,
    private account: AccountService
  ) {
    if (!data.shared) {
      data.shared = "!";
    }
  }

  roles$ = this.account.getRoles();
  ownerName$ = this.account
    .getUser(this.data.file.ownerId)
    .pipe(map((u) => u.fullName || u.name));
  versionColumns = ["name", "date", "delete"];
  unit = MaterialUnit;
  orderParamsFormElements: ITdDynamicElementConfig[] = [];
  orderParamsForm = new FormGroup({});

  ngOnInit() {
    this.dialogRef.updateSize("70vw", "70vh");
    this.system.getSettings(OrderSettings).subscribe((s) => {
      this.orderParamsFormElements = s.customParams || [];
      setTimeout(() => {
        this.orderParamsForm.patchValue(this.data.file.params);
      }, 10);
    });
  }

  canChangeShared() {
    return !this.system.initConfig.mode && !this.auth.roles.includes("seller");
  }

  downloadFile(format?) {
    this.files.openDownloadDialog(this.data.file, format, undefined, true);
  }

  okClick() {
    if (this.orderParamsForm.dirty) {
      this.data.file.params = this.orderParamsForm.value;
    }
    this.dialogRef.close(false);
  }

  deleteBackup(id: string) {}

  close() {
    this.dialogRef.close();
  }
}
