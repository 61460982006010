import { WebDesigner } from "./webdesigner";
import { NavigationMode, Entity, ElasticParam, ElasticParamView, BuilderApplyItem, Designer } from "./designer";
import { vec3, eps, Element, Size, newVector, newVector2, add, middle, pointLineProjection, Box, mat4 } from "./geometry";
import { RenderMode } from "./render/render-scene";
import { pb } from "./pb/scene";
import { roundFloat } from "app/shared/units.service";
import { Observable, forkJoin, from, of } from "rxjs";
import { Property, ModelProperties, ParameterType, PropertyVariant } from "./model-properties";
import { map, concatMap } from "rxjs/operators";
import { generateUid } from "./syncer";
import { FileItem, FilesService } from "app/shared";

interface FileInsert {
  id: number;
  name: string;
  sku?: string;
}

export class EntityModelProperties extends ModelProperties {
  constructor(public e: Entity) {
    super();
  }
}

export class EntityProperty extends Property {
  constructor(source: Property) {
    super();
    this.id = source.id;
    this.catalogId = source.catalogId;
    this.name = source.name;
    this.params = source.params;
    this.variants = source.variants;
    this.value = source.value;
    this.linkedTo = source.linkedTo;
  }
  elements: {e: Entity, value: number}[] = [];

  findMaterial(variant?: PropertyVariant) {
    let index = 0;
    if (!variant) {
      variant = this.find(this.value);
      if (!variant) {
        return;
      }
    }
    for (let param of this.params) {
      if (param.type === ParameterType.Material) {
        return `${this.catalogId}\n${variant.values[index]}`;
      }
      index++;
    }
  }
}

export interface DimensionOptions {
  text?: string;
  prefix?: string;
  postfix?: string;
  fontSize?: number;
  color?: string;
}

export class ModelHandler {
  constructor(public ds: WebDesigner) {

  }

  orthoCamera() {
    this.ds.camera.mode = NavigationMode.Ortho;
    this.ds.camera.orient(vec3.axisy, vec3.axis_z);
    this.ds.zoomToFit();
    this.ds.render.mode = RenderMode.HiddenEdgesVisible;
  }

  orbitCamera(defaultOrientation = false) {
    let perspective = this.ds.camera.perspective;
    this.ds.camera.mode = NavigationMode.Orbit;
    if (perspective && !defaultOrientation) return;
    let viewDir = vec3.fromValues(0.6, 0.5, 0.6);
    let upDir = vec3.cross(vec3.create(), viewDir, vec3.axisy);
    upDir = vec3.cross(vec3.create(), upDir, viewDir);
    vec3.normalize(viewDir, viewDir);
    vec3.normalize(upDir, upDir);
    this.ds.camera.orient(viewDir, upDir);
    this.ds.zoomToFit();
    this.ds.render.mode = RenderMode.Shaded;
  }

  walkCamera() {
    let perspective = this.ds.camera.perspective;
    this.ds.camera.mode = NavigationMode.Walk;
    if (perspective) return;
    let viewDir = vec3.fromValues(0.6, 0.3, 0.6);
    let upDir = vec3.cross(vec3.create(), viewDir, vec3.axisy);
    upDir = vec3.cross(vec3.create(), upDir, viewDir);
    vec3.normalize(viewDir, viewDir);
    vec3.normalize(upDir, upDir);
    this.ds.camera.orient(viewDir, upDir);
    this.ds.zoomToFit();
    this.ds.render.mode = RenderMode.Shaded;
  }

  modelStatistics() {
    let objCount = 0;
    let meshCount = 0;
    let triCount = 0;
    let selTriCount = 0;
    this.ds.root.forAll(e => {
      objCount++;
      let selected = e.isSelected;
      if (e.meshes) {
        for (let mesh of e.meshes) {
          meshCount++;
          let count = Math.round(mesh.indices.length / 3);
          triCount += count;
          if (selected) {
            selTriCount += count;
          }
        }
      }
    });
    return {
      objCount,
      meshCount,
      triCount,
      selTriCount,
      webgl: !!window['WebGLRenderingContext'],
      webgl2: !!window['WebGLRenderingContext']
    }
  };

  static async computeUpdateInfo(model: FileItem, src: Entity, fs: FilesService) {
    let ids = new Set<string>();
    src.forAll(e => {
      if (e.data.model?.id && e.data.model.id !== model.id.toString()) {
        ids.add(e.data.model.id);
      }
    });
    let files = await fs.getFiles(Array.from(ids)).toPromise();
    let updates: BuilderApplyItem[] = [];
    let flush = new Set<string>();
    let insertDate = Date.parse(model.modifiedAt);
    if (files.length > 0) {
      src.forAll(e => {
        if (e.data.model?.id) {
          let file = files.find(f => f.id.toString() === e.data.model.id);
          if (Date.parse(file.modifiedAt) > insertDate) {
            flush.add(file.id.toString());
            updates.push({ uid: e, replace: {
              insertModelId: file.id.toString(),
              modelName: file.name,
              sku: file.sku
            }});
          }
        }
      });
    }
    let level = (item: BuilderApplyItem) => {
      let e = (item.uid as Entity);
      let level = 0;
      let parent = e.parent;
      while (parent) {
        parent = parent.parent;
        level++;
      }
      return level;
    }
    updates.sort((item1, item2) => level(item2) - level(item1));
    return { changes: updates, flush: Array.from(flush) };
  }

  replaceModel(src: Entity, dest: FileInsert) {
    return this.replaceModels([src], dest);
  }

  replaceModels(srcs: Entity[], dest: FileInsert) {
    let changes: BuilderApplyItem[] = srcs.map(e => ({
      uid: e,
      replace: {
        insertModelId: dest.id.toString(),
        modelName: dest.name,
        sku: dest.sku
      }
    }));
    return this.ds.applyBatch('Replace models', changes,
      undefined, undefined, dest.id.toString());
  }

  animateAll(root?: Entity, pos?: number) {
    let ok = false;
    root = root || this.ds.root;
    root.forAll(e => {
      if (e.anim) {
        if (pos === undefined) {
          pos = e.animPos ? 0 : 1;
        }
        this.ds.render.animateEntity(e, pos);
        ok = true;
      }
    });
    return ok;
  }

  static gatherModelProperties(items: Entity[], loader: (id: number) => Observable<Property>, propertyId?: number) {
    let entities: Entity[] = [];
    if (propertyId === null) {
      entities = items;
    } else {
      for (let item of items) {
        item.forAll(e => {
          if (e.data.propInfo) {
            if (propertyId) {
              if (ModelProperties.containsProperty(e.data, propertyId)) {
                entities.push(e);
              }
            } else {
              entities.push(e);
            }
          }
        });
      }
    }
    let toModelProperties = e => new EntityModelProperties(e).load(e.data, loader);
    if (entities.length < 1) {
      return of([] as EntityModelProperties[]);
    }
    return forkJoin(entities.map(toModelProperties));
  }

  static gatherProperties(items: Entity[], loader: (id: number) => Observable<Property>) {
    let modelProps = this.gatherModelProperties(items, loader, undefined);
    return modelProps.pipe(map(list => {
      let props: EntityProperty[] = [];
      for (let mp of list) {
        for (let prop of mp.props) {
          let newProp = props.find(p => p.id === prop.id);
          if (newProp) {
            if (prop.value !== newProp.value) {
              newProp.value = undefined;
            }
          } else {
            newProp = new EntityProperty(prop);
            props.push(newProp);
          }
          newProp.elements.push({e: mp.e, value: prop.value});
        }
      }
      return props;
    }));
  }

  static gatherMaterialsFromProperties(properties: EntityProperty[], first = true) {
    let materials = new Set<string>();
    for (let property of properties) {
      let paramIndex = 0;
      for (let param of property.params) {
        if (param.type === ParameterType.Material) {
          for (let variant of property.variants) {
            materials.add(`${property.catalogId}\n${variant.values[paramIndex]}`);
          }
          if (first) {
            break;
          }
        }
        paramIndex++;
      }
    }
    return Array.from(materials);
  }

  static computePropertyChanges(items: Entity[], loader: (id: number) => Observable<Property>,
      propertyId: number | undefined, editor: (mp: EntityModelProperties) => void | false) {
    let modelProps = this.gatherModelProperties(items, loader, propertyId);
    return modelProps.pipe(
      concatMap(list => {
        let changed = list.filter(mp => editor(mp) !== false);
        let linkedProperties = changed.filter(mp => mp.hasLinkedProperties());
        if (linkedProperties.length > 0) {
          return forkJoin(linkedProperties
            .map(p => p.loadProperties(p.getPropertyMap(), loader)))
            .pipe(map(_ => changed));
        }
        return of(changed);
      }),
      map(list => list.map(mp => {
        let apply = mp.save();
        let change: BuilderApplyItem = {uid: mp.e, ...apply};
        return change;
      })),
    );
  }

  static editProperties(ds: Designer, items: Entity[], loader: (id: number) => Observable<Property>,
      propertyId: number | undefined, editor: (mp: EntityModelProperties) => void | false,
      name = 'Edit properties') {
    return this.computePropertyChanges(items, loader, propertyId, editor).pipe(
      concatMap(changes => from(ds.applyBatch(name, changes, undefined)))
    );
  }

  static applyProperty(ds: Designer, propertyId: number, valueId: number, items: Entity[],
      loader: (id: number) => Observable<Property>) {
    return this.editProperties(ds, items, loader, propertyId, mp => {
      let prop = mp.props.find(p => p.id === propertyId);
      if (prop.value === valueId) {
        return false;
      }
      prop.value = valueId;
    });
  }

  static removeProperty(ds: Designer, propertyId: number, items: Entity[], loader: (id: number) => Observable<Property>) {
    return this.editProperties(ds, items, loader, propertyId, mp => {
      mp.props = mp.props.filter(p => p.id !== propertyId);
    });
  }

  static addProperty(ds: Designer, property: Property, items: Entity[], loader: (id: number) => Observable<Property>) {
    return this.editProperties(ds, items, loader, null, mp => {
      mp.addProperty(property);
    });
  }

  static getParamVariants(param: ElasticParam) {
    if (!param.variants) {
      return;
    }
    let list = param.variants.split('\n').map(s => s.trim()).filter(s => s);
    if (list.length < 2) {
      list = param.variants.split(';').map(s => s.trim()).filter(s => s);
    }
    return list.map(s => {
      let index = s.indexOf(' - ');
      if (index > 0) {
        return {
          name: s.substr(index + 3),
          value: roundFloat(Number(s.substr(0, index)))
        }
      } else {
        return {
          name: s,
          value: roundFloat(Number(s))
        }
      }
    }).filter(v => !Number.isNaN(v.value));
  }

  static gatherParams(items: Entity[]) {
    let params: ElasticParamView[] = [];
    for (let item of items) {
      item.forAll(e => {
        if (e.elastic && e.elastic.params) {
          for (let param of e.elastic.params) {
            let entry = params.find(p => p.name === param.name);
            if (entry) {
              if (entry.size && Math.abs(entry.size - param.size) > eps) {
                entry.size = null;
              }
            } else {
              entry = {
                name: param.name,
                description: param.description,
                size: param.size,
                entitites: [],
                flags: param.flags,
                control: param.variants === '@checkbox' ? 'checkbox' : undefined,
                variants: this.getParamVariants(param)
              }
              entry.size = roundFloat(entry.size);
              params.push(entry);
            }
            entry.entitites.push(e);
          }
        }
      });
    }
    return params;
  }

  getElasticAxis(item: Entity) {
    if (item.elastic) {
      const Pos = pb.Elastic.Position;
      switch (item.elastic.position) {
        case Pos.Left:
        case Pos.Right:
        case Pos.Vertical:
        case Pos.VSplitter:
          return 0;
        case Pos.Back:
        case Pos.Front:
        case Pos.Front:
        case Pos.FSplitter:
          return 2;
      }
    }
    return 1;
  }

  static initDimension3P(e: Entity, p1: Float64Array, p2: Float64Array, p3: Float64Array): Entity | undefined {
    let pos = vec3.fmiddle(p1, p2);
    e.matrix = mat4.fromTranslation(mat4.create(), pos);

    let ok = true;
    let diry = vec3.fsub(p3, p2);
    ok &&= vec3.normalize(diry, diry);
    let dirx = vec3.fsub(p1, p2);
    ok &&= vec3.normalize(dirx, dirx);
    let dirz = vec3.fcross(dirx, diry);
    ok &&= vec3.normalize(dirz, dirz);
    if (!ok) {
      return undefined;
    }
    e.orient(dirz, diry);

    let lp1 = newVector2(e.toLocal(p1));
    let lp2 = newVector2(e.toLocal(p2));
    let lp3 = newVector2(e.toLocal(p3));
    let lp3_1 = add(lp3, newVector(1, 0));
    let dim1 = pointLineProjection(lp1, lp3, lp3_1);
    let dim2 = pointLineProjection(lp2, lp3, lp3_1);
    let textPos = middle(dim1, dim2);
    let size = new Size(lp1, lp2, textPos);
    size.dim1 = dim1;
    size.dim2 = dim2;

    let rect = size.rect;
    let box = new Box();
    box.minx = rect.min.x;
    box.miny = rect.min.y;
    box.maxx = rect.max.x;
    box.maxy = rect.max.y;
    e.elastic = { box, params: [] };
    e.drawing = size;
    e.boxChanged();
    e.changed();
    return e;
  }

  static assignDimensionOptions(e: Entity, options: DimensionOptions) {
    let size = e.drawing
    if (size instanceof Size) {
      if (options.text !== undefined) {
        size.text = options.text;
      }
      if (options.prefix !== undefined) {
        size.prefix = options.prefix;
      }
      if (options.postfix !== undefined) {
        size.postfix = options.postfix;
      }
      if (options.color !== undefined) {
        size.color = options.color;
      }
      if (options.fontSize !== undefined) {
        size.fontSize = options.fontSize;
      }
    }
  }

  static createOrUpdateDrawing(dimension: Entity, drawing?: Element): BuilderApplyItem {
    if (drawing) {
      dimension.drawing = drawing;
      dimension.changed();
    }
    let command: BuilderApplyItem = dimension.uid ? {
      uid: dimension.uidStr
    } : {
      newuid: (dimension.uid = generateUid()).toString(),
      parent: dimension.ds.root
     };
    drawing = drawing || dimension.drawing;
    let rect = drawing.rect;
    dimension.data.drawing = JSON.stringify(drawing.save());
    return {
      ...command,
      parent: dimension.parent,
      matrix: dimension.matrix,
      data: {
        drawing: dimension.data.drawing
      },
      elastic: {
        box: [...rect.min.arr, 0, ...rect.max.arr, 0]
      }
    };
  }
}
