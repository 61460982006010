import {
  Component,
  OnDestroy,
  HostListener,
  NgZone,
  ViewChild,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  ElementRef,
  ViewContainerRef,
  TemplateRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DialogService } from "app/dialogs/services/dialog.service";
import { Observable, combineLatest, Subscription } from "rxjs";
import {
  AuthService,
  FilesService,
  FileItem,
  CatalogService,
  CatalogMaterial,
  createMaterial,
  OrderService,
  Catalog,
  dataURItoFile,
  FileOrderItem,
} from "../shared";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { WebDesigner } from "modeler/webdesigner";
import { DesignerErrorType, DesignerError } from "modeler/builder-designer";
import {
  Entity,
  EntityRay,
  NavigationMode,
  BuilderApplyItem,
  Mesh,
} from "modeler/designer";
import { EditorTool } from "modeler/editor-tool";
import { CreateDimensionTool, MeasureTool } from "modeler/measure-tool";
import { FloorProjectStatistics } from "modeler/floorplanner";
import { RenderMode, materialPointer } from "modeler/render/renderer";
import { glMatrix, vec3, mat4 } from "modeler/geometry";
import {
  ProjectDetailsComponent,
  ProjectDetails,
} from "./project-details/project-details.component";
import { EstimateService, PriceListInfo, PriceList } from "./estimate";
import { EmbedService } from "embed/embed.service";
import {
  MaterialSelectorComponent,
  MaterialViewMode,
} from "./material-selector/material-selector.component";
import {
  ProjectLinkComponent,
  ProjectLinkComponentData,
} from "./project-link/project-link.component";
import {
  ProjectHandler,
  Bookmark,
  ProjectSelectionStatus,
  NewProjectSettings,
} from "modeler/project-handler";
import { WindowService } from "../shared/window.service";
import {
  trigger,
  style,
  transition,
  animate,
  keyframes,
} from "@angular/animations";
import { DragDropTool } from "modeler/move-tool";
import { getEventFullKey } from "../shared/keyboard";
import { CameraTool, MouseInfo } from "modeler/designer-tool";
import { MoveDialogComponent } from "./move-dialog/move-dialog.component";
import { RotateDialogComponent } from "./rotate-dialog/rotate-dialog.component";
import { ModelHistoryComponent } from "./model-history/model-history.component";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import {
  PrintDialogComponent,
  PrintData,
} from "./print-dialog/print-dialog.component";
import { ImageMaker, ImageMakerParams } from "./image-maker";
import { PlannerSettings, OrderSettings } from "../shared/system.service";
import { RoofTool } from "modeler/roof-tool";
import { CoverToolComponent } from "./cover-tool/cover-tool.component";
import { ModelExplorerComponent } from "./model-explorer/model-explorer.component";
import { CopyDialogComponent } from "./copy-dialog/copy-dialog.component";
import {
  takeUntil,
  filter,
  concatMap,
  take,
  map,
  tap,
  catchError,
  debounceTime,
  delay,
  shareReplay,
} from "rxjs/operators";
import { of, merge } from "rxjs";
import {
  ProjectPhotoComponent,
  PhotoDataData,
} from "./project-photo/project-photo.component";
import { SystemService } from "app/shared/system.service";
import { AboutComponent } from "./about/about.component";
import { DatePipe } from "@angular/common";
import { loadModelInsertInfo } from "modeler/syncer";
import { ReplaceDialogComponent } from "./replace-dialog/replace-dialog.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { OrderEditorComponent } from "./orders/order-editor/order-editor.component";
import {
  SpecificationDetails,
  SpecificationComponent,
} from "./specification/specification.component";
import { NewOrderComponent } from "./orders/new-order/new-order.component";
import { NewProjectComponent } from "./new-project/new-project.component";
import { TdFileService } from "app/shared/file/services/file.service";
import { PlannerUI, HttpWrapper, SystemInterface } from "./ui.script";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { PlannerScriptInterface } from "./planner.script";
import { OverlayContainer } from "@angular/cdk/overlay";
import { EditorPropertyGroup, PropertyType } from "modeler/property-editor";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-planner",
  templateUrl: "./planner.component.html",
  styleUrls: ["./planner.component.scss"],
  providers: [EstimateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("propertyPanelAnimation", [
      transition(":enter", [
        style({ transform: "translateY(100%)" }),
        animate("200ms ease-in", style({ transform: "translateY(0%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateY(100%)" })),
      ]),
      transition("* => *", [
        style({ transform: "translateX(100%)" }),
        animate("200ms ease-out", style({ transform: "translateX(0%)" })),
      ]),
    ]),
    trigger("undoAnimation", [
      transition(":increment", [
        animate("200ms ease-out", style({ transform: "rotateZ(-360deg)" })),
      ]),
    ]),
    trigger("redoAnimation", [
      transition(":increment", [
        animate("200ms ease-out", style({ transform: "rotateZ(360deg)" })),
      ]),
    ]),
    trigger("pulseAnimation", [
      transition(":enter", [
        animate(
          "500ms ease",
          keyframes([
            style({ transform: "scale3d(0.01, 0.01, 0.01)", offset: 0 }),
            style({ transform: "scale3d(1.5, 1.5, 1.5)", offset: 0.75 }),
            style({ transform: "scale3d(1, 1, 1)", offset: 1.0 }),
          ])
        ),
      ]),
      transition(":leave", [
        animate(
          "500ms ease",
          keyframes([
            style({ transform: "scale3d(1, 1, 1)", offset: 0 }),
            style({ transform: "scale3d(1.5, 1.5, 1.5)", offset: 0.75 }),
            style({ transform: "scale3d(0.01, 0.01, 0.01)", offset: 1.0 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class ProjectEditorComponent implements OnInit, OnDestroy {
  ds: WebDesigner;
  modelId: string;
  rootId: string;
  loaded = false;
  loadingIndicator$: Observable<boolean>;
  canvasImageData: string;
  fileItem?: FileOrderItem;
  readOnlyMode = true;
  backupId?: string;
  editable = false;
  fileToken?: string;
  projectMode = true;
  cameraParam?: string;
  activePriceId = 0;
  embedded = false;
  project: ProjectHandler;
  propertyEditor = new EditorPropertyGroup(PropertyType.Group);
  bookmarks$: Observable<Bookmark[]>;
  recentFolders: FileItem[] = [];
  settings = new PlannerSettings();
  orderSettings = new OrderSettings();
  status?: ProjectSelectionStatus;
  ui = new PlannerUI(
    undefined,
    this.zone,
    this.dialog,
    this.vcr,
    this.matIconRegistry,
    this.sanitizer,
    this.files,
    this.catalogs
  );

  handset = false;
  error?: DesignerError;
  DesignerErrorType = DesignerErrorType;
  showProperties = false;
  paintMode = false;
  checkModels = true;
  estimateSub: Subscription;

  private destroy = new EventEmitter<void>();
  private scriptInterface = new PlannerScriptInterface(this);

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    public files: FilesService,
    public dialog: DialogService,
    private firm: OrderService,
    public catalogs: CatalogService,
    public snackBar: MatSnackBar,
    public system: SystemService,
    private window: WindowService,
    public estimate: EstimateService,
    public datePipe: DatePipe,
    public cd: ChangeDetectorRef,
    private vcr: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
    private tdFileService: TdFileService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private embed: EmbedService,
    private overlay: OverlayContainer
  ) {
    this.embedded = system.initConfig.mode === "embedded";
    this.auth.isAuthenticated
      .pipe(takeUntil(this.destroy))
      .subscribe((state) => this.authChanged(state));
    this.system
      .getSettings(PlannerSettings)
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => this.applySettings(value));

    this.system
      .getScript("planner")
      .pipe(
        tap((v) => this.scriptLoaded(v)),
        takeUntil(this.destroy)
      )
      .subscribe();
    matIconRegistry.addSvgIconSet(
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icon/planner-icons.svg")
    );
  }

  private scriptLoaded(script: string) {
    if (this.backupId) {
      return;
    }
    let activeThis = this;
    let self = {};
    let result = function (estimate, planner, http, system) {
      if (estimate && planner && http && system) {
        try {
          /* tslint:disable-next-line */
          return eval(script + "\n//# sourceURL=wp/scripts/planner.js;");
        } catch (e) {
          let message = e && e.toString();
          activeThis.snackBar.open("Ошибка исполнения скрипта. " + message);
        }
      }
    }.call(
      self,
      this.estimate,
      this.scriptInterface,
      new HttpWrapper(this.http),
      new SystemInterface(this.system, this.scriptInterface, this.files)
    );
    if (result) {
      this.scriptInterface._initialized();
    }
  }

  private authChanged(value: boolean) {
    if (value) {
      combineLatest(this.firm.getActivePrice(), this.firm.getPrices(-1))
        .pipe(takeUntil(this.destroy))
        .subscribe(([active, allPrices]) => {
          this.applyPriceList(active);
          // add active price list because active can be non-shared system price
          if (active && !allPrices.find((p) => p.id === active.id)) {
            let activeInfo = { ...active, data: undefined };
            allPrices.splice(0, 0, activeInfo);
          }
          this.prices = allPrices;
        });
    } else {
      let seller = this.route.snapshot.queryParams["seller"];
      if (seller) {
        this.firm.getActivePrice(true, seller).subscribe((price) => {
          this.applyPriceList(price);
        });
      }
    }
  }

  private applySettings(value: PlannerSettings) {
    this.settings = value;
    this.estimate.showPrices = value.showPrices;
    if (this.ds) {
      this.ds.sounds = value.sounds;
      this.ds.options.navigator = value.navigatorCube;
      this.ds.render.mode = value.defaultRenderMode;
      this.ds.camera.mode = value.defaultCameraMode;
      this.ds.camera.fovAngle = value.defaultFovAngle;
    }
    this.cd.markForCheck();
  }

  private createDesigner(canvas3d: HTMLCanvasElement) {
    let ds = new WebDesigner(
      canvas3d,
      this.zone,
      this.auth,
      this.catalogs,
      this.snackBar,
      this.breakpointObserver
    );
    ds.serverError.pipe(takeUntil(this.destroy)).subscribe((error) => {
      if (error.type === DesignerErrorType.InvalidAction) {
        this.snackBar.open(error.info || "Invalid action");
        return;
      }
      if (error.type === DesignerErrorType.Archived) {
        this.fileItem.readOnly = "archived";
        return;
      }
      this.error = error;
      this.cd.markForCheck();
      if (error.type === DesignerErrorType.Forbid && this.embedded) {
        this.embed.setLastProjectId(undefined);
        this.snackBar.open("Файл проекта недоступен.");
        this.newProject();
      }
    });
    ds.mouseEvent.pipe(takeUntil(this.destroy)).subscribe((move) => {
      if (!move) {
        this.cd.markForCheck();
      }
    });
    this.project = new ProjectHandler(ds);
    this.bookmarks$ = this.project.bookmarks$();
    this.project.orbitCamera(true);
    this.loadingIndicator$ = ds.processing.pipe(debounceTime(250));
    ds.defaultTool = () => new EditorTool(ds);
    ds.modelChange
      .pipe(debounceTime(1000), takeUntil(this.destroy))
      .subscribe((_) => {
        this.floors = this.project.floors;
        if (this.editable) {
          this.makeThumbnail();
        }
      });
    merge(ds.modelChange, ds.render.texturesLoaded)
      .pipe(debounceTime(1000))
      .subscribe((_) => this.computeEstimate());

    merge(
      ds.selection.change.pipe(map((_) => true)),
      ds.processing.pipe(map((v) => !v)),
      ds.modelChange.pipe(map((_) => true))
    )
      .pipe(
        tap((_) => {
          this.status = undefined;
          this.cd.markForCheck();
        }),
        filter((v) => v),
        debounceTime(100)
      )
      .subscribe((_) => {
        if (this.editable && ds.hasSelection) {
          this.status = this.project.status();
          this.scriptInterface.select$.next(this.status);
          if (this.scriptInterface.onSelect) {
            this.scriptInterface.onSelect(this.status);
          }
          this.cd.markForCheck();
        }
      });
    ds.uiChange$
      .pipe(takeUntil(this.destroy))
      .subscribe((_) => this.cd.markForCheck());
    ds.render.dynamicVisibility = true;
    ds.sounds = this.settings.sounds;
    return ds;
  }

  @ViewChild("canvas3d", { static: true }) canvas3d: ElementRef;

  ngOnInit() {
    try {
      this.ds = this.createDesigner(this.canvas3d.nativeElement);
      this.ui.ds = this.ds;
      let routeParams = combineLatest(
        this.route.params,
        this.route.queryParams,
        this.auth.isAuthenticated
      ).pipe(
        map((result) => ({ p: result[0], q: result[1], auth: result[2] }))
      );
      if (this.embedded) {
        routeParams
          .pipe(
            filter((v) => v.auth),
            takeUntil(this.destroy)
          )
          .subscribe((par) => {
            let projectLink = par.q["project"];
            if (projectLink) {
              this.cameraParam = par.q["camera"];
              let link = this.embed.decodeLink(decodeURIComponent(projectLink));
              if (link && link.id) {
                this.fileToken = link.token;
                this.loadProject(link.id);
                return;
              }
            }
            let projectId = this.embed.getLastProjectId();
            if (projectId) {
              this.embed.goToProject(projectId);
            } else {
              this.embed.setLastProjectId();
              this.snackBar.open("Проект недоступен для редактирования");
              this.router.navigate([""]);
            }
          });
      } else {
        routeParams.pipe(takeUntil(this.destroy)).subscribe((par) => {
          this.cameraParam = par.q["camera"];
          this.fileToken = par.q["token"];
          let id = par.p["id"];
          let backup = par.q["backup"];
          if (id) {
            this.loadProject(id, par.q["root"], backup);
          }
        });
      }
      (this.canvas3d.nativeElement as HTMLCanvasElement).focus();
    } catch (error) {
      this.error = {
        type: DesignerErrorType.WebGL,
        info: error && error.message,
      };
    }
    this.system
      .getSettings(OrderSettings)
      .subscribe((s) => (this.orderSettings = s));
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.handset = result.matches;
      });
  }

  floors: Entity[] = [];
  prices?: PriceListInfo[];

  private loadProject(id: string | number, rootId?: string, backupId?: string) {
    if (!this.ds) {
      // don't load project while component is being destroyed
      return;
    }
    this.error = undefined;
    this.loaded = false;
    this.readOnlyMode = true;
    this.prices = undefined;
    this.modelId = (id || "").toString();
    this.rootId = rootId;
    this.backupId = backupId;
    this.ds.disconnect();
    this.ds
      .loadModel(this.backupId || this.modelId, this.rootId, this.fileToken)
      .then((_) => this.projectLoaded());
    if (this.cameraParam) {
      this.ds.loadCamera(decodeURIComponent(this.cameraParam), true, false);
    }
    this.firm
      .getFileOrder(+id, this.fileToken)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (file) => {
          this.fileItem = file;
          this.projectMode = !file.model;
          if (file.model) {
            setTimeout(() => {
              if (this.modelExplorer) {
                this.modelExplorer.selectFolder(file.parentId);
              }
            }, 100);
          }
          this.readOnlyMode = !!file.readOnly || !!backupId;
          if (!file.catalogId && this.auth.isAdmin.value) {
            // allow edit templates by admins
            this.readOnlyMode = false;
          }
          this.setEditable(!this.readOnlyMode);
        },
        (error) => {
          let type = DesignerErrorType.Network;
          if (error instanceof HttpErrorResponse) {
            if (error.status === 403) {
              type = DesignerErrorType.Forbid;
            }
            if (error.status === 404) {
              type = DesignerErrorType.NotFound;
            }
          }
          this.error = {
            type,
            info:
              error instanceof HttpErrorResponse
                ? `${error.message}`
                : `${error}`,
          };
          this.cd.markForCheck();
        }
      );
  }

  newProject() {
    if (this.embedded) {
      this.router.navigate([""]);
    } else {
      if (this.orderSettings && this.orderSettings.enabled) {
        this.dialog.open(NewOrderComponent);
      } else {
        this.dialog
          .open(NewProjectComponent, { data: false })
          .componentInstance.afterCreate.subscribe((f) =>
            this.router.navigate(["/project", f.id])
          );
      }
    }
  }

  private projectLoaded() {
    this.loaded = true;
    this.floors = this.project.floors;
    this.computeEstimate();

    if (!this.ds.undoName && !this.ds.redoName) {
      let room = this.ds.root.findChild((e) => !!e.data.room, true);
      if (room) {
        room.selected = true;
      }
    }

    if (this.editable && this.fileItem && !this.fileItem.preview) {
      this.makeThumbnail();
    }
    if (this.embedded) {
      this.embed.setLastProjectId(this.modelId);
    }
    if (this.scriptInterface.onLoad) {
      this.scriptInterface.onLoad();
    }
  }

  get isProjectArchived() {
    return this.fileItem && this.fileItem.readOnly === "archived";
  }

  restoreFromArchive() {
    // TODO: display restore progress and success
    this.files
      .restoreFromArchive(this.backupId || this.fileItem.id, this.fileToken)
      .subscribe((_) =>
        this.loadProject(this.fileItem.id, undefined, this.backupId)
      );
  }

  get selected() {
    return this.ds && this.ds.selected;
  }

  selectEntity(e: Entity) {
    this.ds.selected = e;
  }

  get hasSelection() {
    return this.ds.hasSelection;
  }

  get commands() {
    return this.ds.activeTool.commands;
  }

  selectMaterial(material?: CatalogMaterial) {
    let dialogRef = this.dialog.open(MaterialSelectorComponent, {
      width: "50%",
      height: "60%",
      data: material,
    });
    let selector = dialogRef.componentInstance;
    selector.viewMode = MaterialViewMode.Grid;
    selector.displayCatalogs = true;
    selector.select.subscribe((_) => dialogRef.close());
    return selector.select;
  }

  ngOnDestroy() {
    this.destroy.emit();
    this.destroy.complete();
    this.ds.destroy();
    this.project = undefined;
    this.ds = undefined;
  }

  viewAll() {
    this.ds.animateCamera();
    this.ds.zoomToFit(true, false);
  }

  rotateCamera(axis, global = false) {
    let ds = this.ds;
    let rotAxis = global ? axis : ds.camera.NtoGlobal(axis);

    let camera = ds.camera;
    let center = camera.lastRotationCenter;

    if (!center) {
      center = ds.box.center;
      let ray = new EntityRay();

      // find center point on the screen to rotate about
      ray.pos = camera.translation;
      ray.dir = camera.NtoGlobal(vec3.axis_z);
      let zLimits = { near: 0, far: 1000 };
      camera.calcZPlanes(zLimits);

      // we rotate around certain point only if model is close enough to us
      if (camera.perspective && zLimits.near * 2 < ds.box.diagonal) {
        ds.root.intersect(ray);
        if (ray.intersected) {
          center = ray.intersectPos;
        }
      }
      camera.lastRotationCenter = center;
    }

    ds.animateCamera();
    ds.camera.rotate(center, rotAxis, Math.PI / 4);
  }

  cameraRotUp() {
    this.rotateCamera([1, 0, 0]);
  }

  cameraRotDown() {
    this.rotateCamera([-1, 0, 0]);
  }

  cameraRotLeft() {
    this.rotateCamera([0, 1, 0], true);
  }

  cameraRotRight() {
    this.rotateCamera([0, -1, 0], true);
  }

  addWalls(floor?: Entity) {
    if (floor) {
      this.ds.selected = floor;
    }
    if (this.project.findFloorPlan() && this.ds.tool instanceof EditorTool) {
      this.ds.tool.addFloorWalls();
    }
  }

  splitWall() {
    if (this.ds.tool instanceof EditorTool) {
      this.ds.tool.splitWall();
    }
  }

  hiddenEntities = false;

  hideSelection() {
    let selection = this.ds.selection.items;
    selection.forEach((e) => (e.visible = false));
    this.ds.selection.clear();
    this.hiddenEntities = true;
  }

  restoreVisibility() {
    this.hiddenEntities = false;
    this.ds.root.forEach((e) => (e.visible = true));
  }

  toggleVisibility(item: Entity) {
    item.visible = !item.visible;
    item.selected = false;
  }

  get actionHint() {
    if (this.ds.tool) {
      return this.ds.activeTool.hint;
    }
  }

  get isDefaultAction() {
    return this.ds && this.ds.activeTool instanceof EditorTool;
  }

  cancelAction() {
    this.ds.activeTool.cancel();
  }

  get actionCursor() {
    return this.ds && this.ds.activeTool.cursor;
  }

  computeEstimate() {
    if (this.ds && this.ds.root) {
      if (this.estimateSub) {
        this.estimateSub.unsubscribe();
      }
      this.estimateSub = this.estimate
        .compute(this.ds.root, this.ds.render.materials)
        .pipe(takeUntil(this.destroy))
        .subscribe((_) => {
          this.cd.markForCheck();
          this.checkProjectModels();
        });
    }
  }

  private checkProjectModels() {
    if (this.ds.processing.value || !this.editable) return;
    if (!this.checkModels || !this.settings.updateChangedModels) return;
    this.checkModels = false;
    if (this.estimate.missingModels.length > 0) {
      let modelNames = new Set(this.estimate.missingModels.map((m) => m.name));
      this.files
        .findModelsByName(Array.from(modelNames))
        .pipe(
          filter((files) => files.length > 0),
          concatMap((files) => {
            return this.snackBar
              .open(
                "Используемые модели были перемещены в каталогах. Обновить ссылки?",
                "ОБНОВИТЬ",
                { duration: 7000 }
              )
              .onAction()
              .pipe(map((_) => files));
          }),
          concatMap((files) => {
            let changes: BuilderApplyItem[] = [];
            for (let model of this.estimate.missingModels) {
              let file = files.find((f) => f.name === model.name);
              if (file) {
                changes.push({
                  uid: model,
                  data: {
                    model: {
                      id: file.id.toString(),
                      sku: file.sku,
                    },
                  },
                });
              }
            }
            return of(
              this.ds.applyBatch("Обновление ссылок на модели", changes)
            ).pipe(map((_) => changes.length));
          })
        )
        .subscribe((_) =>
          this.snackBar.open("Ссылки на модели успешно обновлены!")
        );
    } else if (this.fileItem) {
      let models = new Set<number>();
      this.ds.root.forAll((e) => {
        if (e.data.model && e.data.model.id) {
          models.add(Number(e.data.model.id));
        }
      });
      this.files
        .getFiles(Array.from(models), false)
        .pipe(
          map((files) =>
            files.filter((f) => f.modifiedAt > this.fileItem.modifiedAt)
          ),
          filter((files) => files.length > 0),
          concatMap((files) => {
            return this.dialog
              .openConfirm({
                message:
                  files.length +
                  " моделей обновлено в каталогах. Обновить их в проекте?",
              })
              .afterClosed()
              .pipe(map((v) => (v ? files : undefined)));
          }),
          filter((v) => !!v),
          concatMap((files) => {
            let changes: BuilderApplyItem[] = [];
            this.ds.root.forAll((e) => {
              if (e.data.model) {
                let file = files.find((f) => f.id === Number(e.data.model.id));
                if (file) {
                  changes.push({
                    uid: e,
                    replace: {
                      insertModelId: file.id.toString(),
                      modelName: file.name,
                      sku: file.sku,
                    },
                  });
                }
              }
            });
            // we need to replace items inside containers first
            let level = (item: BuilderApplyItem) => {
              let e = item.uid as Entity;
              let level = 0;
              let parent = e.parent;
              while (parent) {
                parent = parent.parent;
                level++;
              }
              return level;
            };
            changes.sort((item1, item2) => level(item2) - level(item1));
            let result = this.ds.applyBatch(
              "Обновление моделей в проекте",
              changes,
              undefined,
              undefined,
              files.map((f) => f.id.toString())
            );
            return of(result).pipe(map((_) => changes.length));
          })
        )
        .subscribe((count) =>
          this.snackBar.open(count + " моделей успешно обновлено в проекте!")
        );
    }
  }

  makeThumbnail(manual = false) {
    if (!this.editable && !this.auth.admin) {
      return;
    }
    if (this.rootId && !manual) {
      return;
    }
    let camera: Float64Array;
    if (!manual) {
      camera = mat4.transformation(
        mat4.create(),
        vec3.origin,
        vec3.axisz,
        vec3.axisy
      );
      mat4.rotateY(camera, camera, glMatrix.toRadian(20));
      mat4.rotateX(camera, camera, glMatrix.toRadian(-40));
    }
    this.ds.render.texturesLoaded
      .pipe(
        filter((v) => v),
        concatMap((_) =>
          this.ds.render.takePicture({
            size: manual ? 512 : 256,
            mode: RenderMode.Shaded,
            camera,
            perspective: true,
            drawings: false,
            fit: true,
            effects: false,
            invalidate: true,
          })
        ),
        concatMap((thumb) => {
          if (manual) {
            let blob = dataURItoFile(thumb);
            return this.files.updateCustomThumbnail(
              this.modelId,
              blob,
              undefined,
              this.fileToken
            );
          }
          return this.files.updateThumbnail(
            this.modelId,
            thumb,
            this.fileToken
          );
        }),
        take(1),
        takeUntil(this.destroy)
      )
      .subscribe((_) => {
        if (manual) {
          this.snackBar.open($localize`Thumbnail updated`);
        }
      });
  }

  modelDrag(file: FileItem) {
    if (!this.ds.render.gl || file.folder) {
      return;
    }
    let tool = new DragDropTool(
      this.ds,
      file.id.toString(),
      file.name,
      file.sku,
      file.insertInfo
    );
    tool.openDoors = this.settings.doorAnimation;
    this.ds.run(tool).then((model) => this.afterModelInsert(file, model));
  }

  async afterModelInsert(file: FileItem, model: Entity) {
    if (model && model.catalog !== file.catalogId) {
      let { changes, flush } = await ProjectHandler.computeUpdateInfo(
        file,
        model,
        this.files
      );
      if (changes.length > 0) {
        this.snackBar
          .open(
            $localize`There are outdates parts inside added model. Would you like to update them?`,
            $localize`OK`
          )
          .onAction()
          .subscribe(async (_) => {
            await this.ds.applyBatch(
              "Update parts",
              changes,
              undefined,
              "amend",
              flush
            );
            this.snackBar.open($localize`Model parts updated`);
          });
      }
    }
  }

  materialDrag(m: CatalogMaterial) {}

  moveSelection() {
    this.dialog
      .open(MoveDialogComponent)
      .afterClosed()
      .pipe(filter((v) => v))
      .subscribe((newValue: number[]) => {
        let shift = vec3.fromValues(
          newValue[0] || 0,
          newValue[1] || 0,
          newValue[2] || 0
        );
        if (!vec3.empty(shift)) {
          let dir = this.ds.selectedItems[0].NtoGlobal(shift);
          this.ds.applyToSelection("Move selection", (e) => {
            let localDir = e.parent.NtoLocal(dir);
            e.translate(localDir);
            return { matrix: e.matrix };
          });
        }
      });
  }

  rotateSelection() {
    this.dialog
      .open(RotateDialogComponent)
      .afterClosed()
      .pipe(filter((v) => v))
      .subscribe((data) => {
        if (data.angle) {
          this.ds.rotateSelection(data.angle, vec3.fromAxis(data.axis));
        }
      });
  }

  removeSelection() {
    if (this.editable) {
      this.project.removeSelection();
    }
  }

  cancelCommand() {
    if (this.ds.tool) {
      this.ds.tool.escape();
    }
  }

  shortcuts = new Map<string, (event: KeyboardEvent) => void>([
    ["q", (_) => this.moveSelection()],
    ["w", (_) => this.rotateSelection()],
    ["r", (_) => this.switchRenderMode()],
    ["n", (_) => this.switchNavigationMode()],
    ["z", (_) => this.viewAll()],
    ["delete", (_) => this.removeSelection()],
    ["escape", (_) => this.cancelCommand()],
    ["shift.escape", (_) => this.cancelCommand()],
    ["control.a", (_) => this.project.selectAll()],
    ["control.z", (_) => this.ds.undo()],
    ["control.y", (_) => this.ds.redo()],
    ["control.shift.u", (_) => this.displaySpector()],
    ["arrowup", (_) => this.moveCameraForward(50)],
    ["arrowdown", (_) => this.moveCameraForward(-50)],
    ["arrowleft", (_) => this.rotateCameraAround(1)],
    ["arrowright", (_) => this.rotateCameraAround(-1)],
    ["shift.arrowup", (_) => this.moveCameraForward(300)],
    ["shift.arrowdown", (_) => this.moveCameraForward(-300)],
  ]);

  @HostListener("body:keydown", ["$event"])
  hotkeys(event: KeyboardEvent) {
    if (event.target instanceof HTMLElement) {
      let elem = event.target;
      if (elem.tagName === "INPUT") {
        return;
      }
      let overlay = this.overlay.getContainerElement();
      while (elem) {
        if (elem === overlay) {
          return;
        }
        elem = elem.parentElement;
      }
    }
    if (event.defaultPrevented) {
      return;
    }
    let shortcut = getEventFullKey(event);
    if (!this.ds.activeTool.handleKeyDown(event)) {
      let handler = this.shortcuts.get(shortcut);
      if (handler) {
        handler(event);
        event.preventDefault();
        return false;
      }
    }
  }

  private moveCameraForward(delta: number) {
    if (this.ds.tool instanceof CameraTool) {
      this.ds.tool.moveCameraForward(undefined, delta);
    }
  }

  private rotateCameraAround(speed: number) {
    if (this.ds.camera.mode !== NavigationMode.Ortho) {
      this.ds.animateCamera();
      let yAxis = vec3.fromValues(0.0, 1.0, 0.0);
      this.ds.camera.globalRotate(
        this.ds.camera.translation,
        yAxis,
        glMatrix.toRadian(speed)
      );
    }
  }

  private switchRenderMode() {
    if (this.ds.render.mode === RenderMode.HiddenEdgesVisible) {
      this.ds.render.mode = RenderMode.ShadedWithEdges;
    } else {
      this.ds.render.mode = RenderMode.HiddenEdgesVisible;
    }
  }

  private switchNavigationMode() {
    if (this.ds.camera.mode === NavigationMode.Ortho) {
      this.project.orbitCamera();
    } else {
      this.project.orthoCamera();
    }
  }

  measureDistance() {
    this.ds.tool = new MeasureTool(this.ds, this.createImageMaker());
  }

  dimensionTool() {
    this.ds.tool = new CreateDimensionTool(this.ds);
  }

  takePhoto() {
    let image$ = of(0)
      .pipe()
      .pipe(
        delay(250),
        concatMap((_) =>
          this.ds.render.takePicture({
            width: this.ds.canvas.width,
            height: this.ds.canvas.height,
            taa: true,
            fit: false,
            background: true,
          })
        )
      );
    let data = { image$, file: this.fileItem } as PhotoDataData;
    this.dialog.open(ProjectPhotoComponent, { data });
  }

  getViewLink(token?: string) {
    let origin = window.location.origin;
    let link = `${origin}/project/${this.modelId}`;
    let params = "";
    let separator = "";
    let addParam = (key, value) => {
      params += separator + key + "=" + encodeURIComponent(value);
      separator = "&";
    };
    if (this.embedded) {
      link = `${origin}/editor`;
      let project = this.embed.encodeLink({ id: Number(this.modelId), token });
      addParam("project", project);
    } else if (token) {
      addParam("token", token);
    }
    if (this.auth.hasRole("seller")) {
      addParam("seller", this.auth.userId);
    }
    addParam("camera", this.ds.saveCamera());
    if (this.settings.shortLinks) {
      return of(params).pipe(
        concatMap((p) => this.system.shortenUrl(p)),
        map((short) => `${origin}/p/${this.modelId}?${short}`),
        shareReplay(1)
      );
    }
    if (params) {
      link += "?" + params;
    }
    return of(link);
  }

  shareLinkDialog(options?: {
    name?: string;
    email?: string;
    emails?: string[];
  }) {
    this.files
      .generateFileTokens(this.modelId, this.fileToken)
      .subscribe((tokens) => {
        let data: ProjectLinkComponentData = {
          id: this.fileItem.id,
          name: this.fileItem.name,
          url: this.getViewLink(
            this.fileItem.shared === "*" ? undefined : tokens.read
          ),
          editableUrl: undefined,
          scriptInterface: this.scriptInterface,
          email: (this.fileItem.client && this.fileItem.client.email) || "",
          ...options,
        };
        if (tokens.write) {
          data.editableUrl = this.getViewLink(tokens.write);
        }
        this.dialog.open(ProjectLinkComponent, { data, width: "75%" });
      });
  }

  shareLinkDialogAction() {
    this.scriptInterface.actions.shareLinkDialog();
  }

  saveDefaultCamera() {
    this.ds
      .execute({
        type: "set-key",
        key: "camera",
        value: this.ds.saveCamera(),
      })
      .then((_) =>
        this.snackBar.open($localize`Сamera position successfully saved`)
      );
  }

  displayModelStatistics() {
    this.dialog.open(ModelHistoryComponent, { data: this.project });
  }

  createImageMaker() {
    let hiddenArray: Entity[] = [];
    this.ds.root.forAll((e) => {
      if (e.visible && e.data.model && !this.estimate.contains(e)) {
        let wallElem = e.parent && e.parent.data.wall;
        if (!wallElem) {
          hiddenArray.push(e);
        }
      }
    });
    return new ImageMaker(this.ds, hiddenArray);
  }

  print() {
    let imageMaker = this.createImageMaker();
    let user = {
      id: this.auth.userId,
      name: this.auth.fullName,
      address: this.auth.address,
      phone: this.auth.phone,
      email: this.auth.email,
    };
    let data: PrintData = {
      readOnly: this.readOnlyMode,
      embedded: this.embedded,
      user,
      client: this.fileItem.client,
      order: this.fileItem,
      specification: this.estimate.gatherElements(true),
      totalPrice: Math.round(this.estimate.price),

      currentDate: (format = "dd.MM.yyyy") =>
        this.datePipe.transform(new Date(), format),
      renderImage: (params?: ImageMakerParams) =>
        imageMaker.renderImage(params),

      planner: this.scriptInterface,
      http: new HttpWrapper(this.http),
      estimate: this.estimate,
    };
    let config = { data, minWidth: "30%", minHeight: "40%" };
    this.dialog.open(PrintDialogComponent, config);
  }

  editClient() {
    this.firm
      .getOrder(this.fileItem.id)
      .pipe(
        concatMap((order) => {
          order = order || { id: this.fileItem.id, status: "" };
          let config = { minWidth: "50%", data: order };
          return this.dialog.open(OrderEditorComponent, config).afterClosed();
        }),
        filter((v) => v),
        concatMap((order) => this.firm.setOrder(order.id, order))
      )
      .subscribe((order) => {
        this.fileItem.name = order.name || this.fileItem.name;
        this.fileItem.client = order.client;
        this.fileItem.status = order.status;
        this.cd.markForCheck();
      });
  }

  cloneProject() {
    let nameTest = new RegExp("(.*) - копия(\\((\\d*)\\)|)");
    let matches = nameTest.exec(this.fileItem.name);
    let copyCounter = "";
    let name = this.fileItem.name;
    if (matches && matches.length === 4) {
      let index = 2;
      if (!Number.isNaN(Number.parseInt(matches[3], 10))) {
        index = Number.parseInt(matches[3], 10) + 1;
      }
      copyCounter = `(${index})`;
      name = matches[1];
    }

    this.dialog
      .openPrompt({
        message: `Создать копию проекта`,
        value: `${name} - копия${copyCounter}`,
      })
      .afterClosed()
      .pipe(
        filter((v) => v),
        concatMap((copyName) =>
          this.files.createProject(copyName, {
            flushModelId: this.fileItem.id.toString(),
            type: "load",
            file: this.fileItem.id,
          })
        ),
        concatMap((newProject) => {
          let order = {
            id: newProject.id,
            status: this.fileItem.status,
            client: this.fileItem.client,
          };
          if (order.client) {
            return this.firm.setOrder(newProject.id, order);
          }
          return of(order);
        }),
        map((order) => order.id)
      )
      .subscribe(
        (id) => {
          if (!this.embedded) {
            this.router.navigate(["/project", id]);
          } else {
            this.embed.goToProject(id);
          }
        },
        (e) => alert(e)
      );
  }

  specification() {
    let data: SpecificationDetails = {
      file: { ...this.fileItem },
      estimate: this.estimate,
      order: this.firm.getOrder(this.fileItem.id),
      scriptInterface: this.scriptInterface,
      updateClient: (client) => (this.fileItem.client = client),
    };
    this.dialog.open(SpecificationComponent, { data });
  }

  projectDetails(tab: number) {
    let data: ProjectDetails = {
      file: { ...this.fileItem },
      revision: this.ds.lastSyncRevision.toString(),
      tab: tab || 0,
      shared: this.fileItem.shared,
      readOnly: !this.editable,
      estimate: this.estimate,
      backups: this.files.getProjectBackups(this.fileItem.id),
      order: this.firm.getOrder(this.fileItem.id),
      statistics: new FloorProjectStatistics(this.ds.root),
      print: () => this.print(),
      scriptInterface: this.scriptInterface,
    };
    this.dialog
      .open(ProjectDetailsComponent, { data })
      .afterClosed()
      .subscribe((action) => {
        if (action === "order") {
          this.setEditable(false);
        } else if (action === "clone") {
          this.cloneProject();
        } else if (action === "remove") {
          this.removeProject();
        } else if (action === "archive") {
          this.archiveProject();
        }
        if (action === "backup") {
          this.files
            .backupProject(this.fileItem.id)
            .subscribe((_) =>
              this.snackBar.open("Резервная копия успешно создана")
            );
        } else if (
          typeof action === "string" &&
          action.includes("deleteBackup")
        ) {
          const backupId = Number(action.split("/")[1]);
          this.files
            .deleteBackupProject(this.fileItem.id, backupId)
            .subscribe((_) =>
              this.snackBar.open("Резервная копия успешно удалена")
            );
        } else {
          if (data.shared === "!") {
            data.shared = undefined;
          }
          if (data.shared !== this.fileItem.shared) {
            this.files.share(this.fileItem, data.shared).subscribe((_) => {
              this.fileItem.shared = data.shared;
            });
          }
          if (data.file.name !== this.fileItem.name) {
            this.files
              .renameFile(this.fileItem, data.file.name)
              .subscribe((_) => {
                this.fileItem.name = data.file.name;
                this.cd.markForCheck();
              });
          }
          if (data.file.params !== this.fileItem.params) {
            this.firm
              .setOrder(this.fileItem.id, { id: 0, params: data.file.params })
              .subscribe((_) => {
                this.fileItem.params = data.file.params;
                this.computeEstimate();
              });
          }
          if (data.file.canArchive !== this.fileItem.canArchive) {
            this.files
              .setCanArchive(this.fileItem, data.file.canArchive)
              .subscribe((_) => {
                this.fileItem.canArchive = data.file.canArchive;
              });
          }
        }
      });
  }

  removeProject() {
    this.dialog
      .openConfirm({
        message: $localize`Remove project ${this.fileItem.name}? All changes will be lost.`,
      })
      .afterClosed()
      .subscribe((accept) => {
        if (accept) {
          this.files.removeFile(this.fileItem).subscribe((_) => {
            if (this.embed) {
              this.embed.setLastProjectId(undefined);
              this.router.navigate([""]);
            } else if (this.fileItem.catalogId) {
              this.router.navigate(["/projects"]);
            } else {
              this.router.navigate(["/admin", "templates"]);
            }
          });
        }
      });
  }

  archiveProject() {
    this.dialog
      .openConfirm({
        message: $localize`Archive project ${this.fileItem.name}?`,
      })
      .afterClosed()
      .pipe(
        filter((v) => v),
        concatMap((_) => this.files.archiveProject(this.fileItem.id))
      )
      .subscribe((_) => this.router.navigate(["/projects"]));
  }

  renameProject() {
    this.dialog
      .openPrompt({
        message: $localize`Project name`,
        value: this.fileItem.name,
      })
      .afterClosed()
      .pipe(
        filter((v) => v),
        concatMap((v) => this.files.renameFile(this.fileItem, v))
      )
      .subscribe((file) => (this.fileItem.name = file.name));
  }

  usePriceList(event: MatSelectChange) {
    this.loadPriceList(event.value);
  }

  @ViewChild(ModelExplorerComponent) modelExplorer: ModelExplorerComponent;

  private loadPriceList(id: number) {
    if (id > 0) {
      this.firm.getPrice(id).subscribe((priceList) => {
        this.applyPriceList(priceList);
      });
    } else {
      this.applyPriceList(undefined);
    }
  }

  private applyPriceList(priceList?: PriceList) {
    if (priceList) {
      this.activePriceId = priceList.id;
      this.estimate.priceList = priceList;
    } else {
      this.activePriceId = 0;
      this.estimate.priceList = undefined;
    }
    this.computeEstimate();
    if (this.modelExplorer) {
      this.modelExplorer.reload();
    }
  }

  private loadScript(src) {
    return new Promise(function (resolve, reject) {
      let s = document.createElement("script");
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  }

  private displaySpector() {
    this.loadScript("/external/spector.bundle.js").then((_) => {
      window.setTimeout((_) => {
        console.log("Init SPECTOR");
        let spector = new window["SPECTOR"].Spector();
        spector.displayUI();
        let canvas3d = document.getElementById("canvas3d");
        spector.onCaptureStarted.add((_) => {
          console.log("SPECTOR capture started");
          this.ds.invalidate();
          window.setTimeout((_) => this.ds.invalidate(), 100);
        });
        // spector.onCapture.add(_ => (this.loading = false));
        spector.captureCanvas(canvas3d);
        this.ds.invalidate();
      }, 0);
    });
  }

  get isDragDrop() {
    return this.ds.tool instanceof DragDropTool;
  }

  undoRunCount = 0;
  redoRunCount = 0;

  undo() {
    this.undoRunCount++;
    this.ds.undo();
  }

  redo() {
    this.redoRunCount++;
    this.ds.redo();
  }

  addRoof() {
    this.system.getSettings(NewProjectSettings).subscribe((materials) => {
      let material = materials.roof || createMaterial("Roof");
      this.ds.tool = new RoofTool(this.ds, material);
    });
  }

  setEditable(value: boolean) {
    this.editable = value;
    this.readOnlyMode = !value;
    this.ds.editable = value;
    this.ds.invalidate();
    if (value) {
      this.checkProjectModels();
    }
    this.cd.markForCheck();
  }

  startEditing() {
    this.files
      .isFileLocked(this.modelId)
      .pipe(
        concatMap((locked) => {
          if (locked) {
            return this.dialog
              .openConfirm({
                message:
                  "Проект заблокирован для редактирования. Разблокировать?",
              })
              .afterClosed()
              .pipe(
                concatMap((v) =>
                  v ? this.files.lock(this.modelId, false) : of(false)
                )
              );
          }
          return of(true);
        }),
        filter((v) => !!v)
      )
      .subscribe((_) => this.setEditable(true));
  }

  linkQueryParams(back = false) {
    return this.project && this.project.linkQueryParams(back);
  }

  downloadFile(format = "wpm", root = "", materials = true, saveAs = true) {
    root = root || this.ds.selected?.uidStr || "";
    return this.tdFileService.openDownloadDialog(
      this.fileItem,
      format,
      root,
      materials,
      saveAs
    );
  }

  contextMenuPosition = { x: "0px", y: "0px" };

  contextMenu(event: MouseEvent, trigger: MatMenuTrigger) {
    event.preventDefault();
    if (!this.ds.activeTool.moving) {
      this.contextMenuPosition.x = event.clientX + "px";
      this.contextMenuPosition.y = event.clientY + "px";
      trigger.openMenu();
    }
  }

  canvasDrop(event: DragEvent) {
    let materialData = event.dataTransfer.getData("material");
    if (materialData) {
      let material = JSON.parse(materialData) as CatalogMaterial;
      let mouse = new MouseInfo(event);
      let ray = this.ds.tool.createRay(mouse);
      if (this.ds.tool.intersect(ray) && ray.entity) {
        let e = ray.entity as Entity;
        let model = e.findParent((p) => !!p.data.model);
        let paintEntity = e.findParent(
          (p) => (p.data.model && !p.data.model.sku) || p.data.paint
        );
        if (!model && ray.mesh) {
          // paint meshes outside models (walls, floors, rooms)
          this.ds.apply("Painting", {
            uid: ray.entity,
            paint: {
              material: material.name,
              catalog: material.catalogId,
              faces: [ray.entity.meshes.indexOf(ray.mesh)],
            },
          });
          return;
        } else if (paintEntity && ray.mesh) {
          // apply material map to models without sku or with paint flag
          let props =
            paintEntity.data.propInfo && paintEntity.data.propInfo.props;
          if (!props) {
            let materials =
              paintEntity.data.propInfo && paintEntity.data.propInfo.materials;
            let revision =
              (paintEntity.data.propInfo &&
                paintEntity.data.propInfo.revision) ||
              0;
            revision++;
            materials = materials || [];
            materials.push({
              old: (ray.mesh as Mesh).material,
              new: materialPointer(material.catalogId, material.name),
            });
            this.ds.apply("Painting", {
              uid: paintEntity,
              data: {
                propInfo: {
                  materials,
                  revision,
                },
              },
            });
            return;
          }
        }
      }
      this.snackBar.open($localize`Can not paint specified element`);
    }
  }

  popupPos() {
    let pos = this.ds.selection.pos;
    let sel = this.ds.selected;
    if (pos && sel) {
      let screen = this.ds.toScreen(sel.toGlobal(pos));
      let region = 100;
      let width = this.ds.canvas.width;
      let height = this.ds.canvas.height;
      if (
        screen &&
        screen.x > -region &&
        screen.y > -region &&
        screen.x < width + region &&
        screen.y < height + region
      ) {
        screen.x = Math.min(screen.x, this.ds.canvas.width - 100);
        screen.x = Math.max(screen.x, 40);
        screen.y = Math.min(screen.y, this.ds.canvas.height - 80);
        screen.y = Math.max(screen.y, 0);
        return {
          left: screen.x + "px",
          top: screen.y + "px",
        };
      }
    }
    return { visibility: "hidden" };
  }

  @ViewChild(CoverToolComponent) coverTool: CoverToolComponent;

  enablePaintMode() {
    this.paintMode = true;
    this.offers = undefined;
    this.status = undefined;
    this.cd.markForCheck();
    setTimeout((_) => {
      if (this.coverTool) {
        this.coverTool.edit(this.ds);
      }
    }, 1);
  }

  selectModel(
    catalogOrFolder?: Catalog | number,
    activeFileId?: number,
    type?: string
  ): Observable<FileItem> {
    let dialogRef = this.dialog.open(ModelExplorerComponent, {
      viewContainerRef: this.vcr,
      width: "50%",
      height: "60%",
    });
    let selector = dialogRef.componentInstance;
    if (type !== undefined && this.settings.replaceByType) {
      selector.fileFilter = (f: FileItem) => {
        if (f.insertInfo) {
          let info = loadModelInsertInfo(f.insertInfo);
          if (info) {
            return info.type === type;
          }
        }
        return true;
      };
    }
    selector.recentFolders = this.recentFolders;
    if (catalogOrFolder) {
      if (typeof catalogOrFolder === "number") {
        selector.selectFolder(catalogOrFolder);
      } else {
        selector.selectCatalog(catalogOrFolder);
      }
    }
    if (activeFileId) {
      selector.activateFile(activeFileId);
    }
    return selector.fileSelected.pipe(tap((_) => dialogRef.close()));
  }

  async copy() {
    if (!(await this.project.copySelection())) {
      this.snackBar.open($localize`Failed to copy selected object`);
    }
  }

  bulkReplace() {
    let dialogRef = this.dialog.open(ReplaceDialogComponent, {
      viewContainerRef: this.vcr,
      width: "50%",
      height: "60%",
      data: this.project,
    });
    let selector = dialogRef.componentInstance;
    selector.selectModel = this.selectModel.bind(this);
  }

  replaceSelection() {
    let originals = this.ds.selectedItems;
    let original = originals[0];
    let catalogOrFolder$ = of(undefined);
    let modelId: number;
    if (original.data.model && original.data.model.id) {
      modelId = Number(original.data.model.id);
      catalogOrFolder$ = this.files.getFile(modelId).pipe(
        map((file) => file.parentId),
        catchError((_) => this.catalogs.getCatalog(original.catalog))
      );
    } else if (original.catalog) {
      catalogOrFolder$ = this.catalogs.getCatalog(original.catalog);
    }
    let ds = this.ds;
    catalogOrFolder$
      .pipe(
        catchError((_) => of(undefined)),
        concatMap((data) => this.selectModel(data, modelId, original.type))
      )
      .subscribe((f) => {
        this.project.replaceModels(originals, f).then((data) => {
          if (data && data.modelId) {
            let model = ds.entityMap[data.modelId];
            model.selected = true;
          }
          let replaceCount = data?.entities?.length;
          let message = replaceCount
            ? $localize`${replaceCount} model(s) succefully replaced`
            : $localize`No model has been replaced`;
          this.dialog.snack(message);
        });
      });
  }

  symmetryEntity() {
    this.ds
      .applyToSelection("Symmetry", (e) => ({ symmetry: true }))
      .then((_) => {
        if (this.ds.selected) {
          this.ds.selection.pos = this.ds.selected.sizeBox.center;
        }
      });
  }

  advancedCopy() {
    let axis = this.project.getElasticAxis(this.ds.selected);
    this.dialog
      .open(CopyDialogComponent, { data: axis })
      .afterClosed()
      .pipe(filter((v) => v))
      .subscribe((v) => this.project.advancedCopy(v));
  }

  removeAuxLines() {
    this.project.removeAuxLines(this.project.selectedFloorPlan);
  }

  addBookmark(list: Bookmark[]) {
    this.dialog
      .openPrompt({ message: $localize`Bookmark name` })
      .afterClosed()
      .pipe(filter((v) => v))
      .subscribe((name) => {
        list.push({ id: this.ds.undoOperationId.toString(), name });
        this.project.setBookMarks(list);
      });
  }

  currentBookmark() {
    let id = this.ds.undoOperationId;
    return id && id.toString();
  }

  goToBookmark(bookmark: Bookmark) {
    this.ds.execute({ type: "goto", id: bookmark.id.toString() }).then((_) => {
      this.snackBar.open($localize`Project updated`);
    });
  }

  removeBookmark(list: Bookmark[], bookmark: Bookmark, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    let index = list.indexOf(bookmark);
    list.splice(index, 1);
    this.project.setBookMarks(list);
  }

  restoreBackup() {
    this.files
      .restoreProject(this.fileItem.id, this.backupId)
      .subscribe((_) => this.router.navigate(["/project", this.fileItem.id]));
  }

  toogleCollisionCheck() {
    this.ds.options.collisions = !this.ds.options.collisions;
  }

  about() {
    this.dialog.open(AboutComponent);
  }

  offers: number[];

  showOffers() {
    if (this.selected) {
      this.paintMode = false;
      this.status = undefined;
      this.offers = this.selected.data.model.offers;
    }
  }

  hasHiddenLayers() {
    return this.ds.layers.some((l) => !l.visible);
  }

  showAllLayers() {
    for (let layer of this.ds.layers) {
      layer.visible = true;
    }
  }

  toggleFullScreen() {
    this.window.toggleFullScreen(this.vcr.element.nativeElement);
  }

  modelExplorerActivate(value: number) {
    if (value === 0 && this.modelExplorer) {
      this.modelExplorer.selectFolder(undefined);
    }
  }

  @ViewChild("compressResultTemplate", { static: true })
  compressResultTemplate?: TemplateRef<any>;
  compressResult: any;

  compress() {
    this.ds
      .execute({ type: "compress", name: "Draco-compress" })
      .then((data) => {
        this.compressResult = data;
        this.snackBar.openFromTemplate(this.compressResultTemplate);
      });
  }
}
