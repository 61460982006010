import { Component, OnDestroy, Input, EventEmitter, Output, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { WebDesigner } from 'modeler/webdesigner';
import { Property, PropertyVariant } from 'modeler/model-properties';
import {
  CatalogMaterial,
  CatalogService,
  createMaterial,
  CatalogMaterialCache,
  roundFloat
} from 'app/shared';
import { FloorBuilder } from 'modeler/floorplanner';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Entity, SizeInfo, BuilderApplyInfo, ElasticParamView } from "modeler/designer";
import { Subscription, Observable, of, combineLatest, BehaviorSubject, merge } from 'rxjs';
import { MaterialSelectorComponent, MaterialViewMode } from '../material-selector/material-selector.component';
import { ProjectHandler } from 'modeler/project-handler';
import { glMatrix, Contour, vec3, Size, Element } from 'modeler/geometry';
import { contourArea } from 'modeler/geometry/geom_algorithms';
import { MathCalculator } from 'modeler/math-calculator';
import { pb } from 'modeler/pb/scene';
import { map, auditTime, shareReplay, concatMap, filter, catchError, take} from 'rxjs/operators';
import { ModelHandler, EntityProperty } from 'modeler/model-handler';
import { EstimateService } from '../estimate';
import { EditorProperty, EditorPropertyGroup, PropertyType } from 'modeler/property-editor';

function splitArray<T>(items: T[], filter: (value: T) => boolean) {
  let items1: T[] = [];
  let items2: T[] = [];
  for (let item of items) {
    if (filter(item)) {
      items1.push(item);
    } else {
      items2.push(item);
    }
  }
  return { items1, items2 };
}

@Component({
  selector: "app-property-editor",
  templateUrl: "./property-editor.component.html",
  styleUrls: ["./property-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyEditorComponent implements OnInit, OnDestroy {
  constructor(
    private catalogService: CatalogService,
    private dialogs: MatDialog,
    private cd: ChangeDetectorRef,
    private estimate: EstimateService,
  ) {
    let properties$ = of(undefined).pipe(
      concatMap(_ => ModelHandler.gatherProperties(this.ds.selectedItems, this.propertyLoader)),
      map(props => {
        this.loadMaterials(props);
        let splittedProps = splitArray(props, prop => {
          return prop.elements.some(el => {
            let parentModel = el.e.findParent(p => !!(p.data.model && p.data.model.id), false);
            return parentModel && parentModel.selected;
          });
        });
        return { modelProps: splittedProps.items1, elementsProps: splittedProps.items2 };
      })
    );

    let params$ = of(undefined).pipe(
      map(_ => {
        let params = ProjectHandler.gatherParams(this.ds.selection.items);
        let splittedParams = splitArray(params, param => {
          return param.entitites.some(e => {
            let parentModel = e.findParent(p => !!(p.data.model && p.data.model.id), false);
            return parentModel && parentModel.selected;
          });
        })
        return { modelParams: splittedParams.items1, elementsParams: splittedParams.items2 };
      })
    );

    let propertiesAndParams$ = this.propertyUpdated$.pipe(
      filter(_ => !!this.ds),
      concatMap(_ => combineLatest([properties$, params$])),
      shareReplay()
    );

    this.modelPropsAndParams$ = propertiesAndParams$.pipe(
      map(([props, params]) => {
        return { props: props.modelProps, params: params.modelParams };
      })
    );

    this.elementsPropsAndParams$ = propertiesAndParams$.pipe(
      map(([props, params]) => {
        return { props: props.elementsProps, params: params.elementsParams };
      })
    );
  }

  private intilizePropertyGroup() {
    let propertyGroup = new EditorPropertyGroup(PropertyType.Group);
    this._selectedItems = this.selectedItems;
    propertyGroup.addText({
      name: $localize`Name`,
      getter: (selected: Entity) => {
        return selected.name || '';
      },
      setter: (value: string) => (this.renameSelection(value))
    });

    propertyGroup.addText({
      name: $localize`Description`,
      getter: (selected: Entity) => {
        const description = selected.data.model?.description;
        return description || null;
      }
    });

    propertyGroup.addNumber({
      name: $localize`Width`,
      getter: () => {
        const value = this.sizeInfo?.x || null;
        if (value !== null && !this.sizeInfo.xe) {
          return this.propertyEditor.readOnlyValue(value);
        }
        return value;
      },
      setter: (value: string) => (this.resizeModel('#width', value))
    });

    propertyGroup.addNumber({
      name: $localize`Height`,
      getter: () => {
        const value = this.sizeInfo?.y || null;
        if (value !== null && !this.sizeInfo.ye) {
          return this.propertyEditor.readOnlyValue(value);
        }
        return value;
      },
      setter: (value: string) => (this.resizeModel('#height', value))
    });

    propertyGroup.addNumber({
      name: $localize`Depth`,
      getter: () => {
        const value = this.sizeInfo?.z || null;
        if (value !== null && !this.sizeInfo.ze) {
          return this.propertyEditor.readOnlyValue(value);
        }
        return value;
      },
      setter: (value: string) => (this.resizeModel('#depth', value))
    });

    propertyGroup.addText({
      name: $localize`Price`,
      getter: (selected: Entity) => {
        let priceElem = this.estimate.findElement(selected);
        if (priceElem) {
          let value = priceElem.fullPrice;
          return this.propertyEditor.readOnlyValue(value);
        }
        return null;
      },
      suffix: '₽'
    }).cssClass = 'wp-price-property';

    propertyGroup.addNumber({
      name: $localize`Height above the floor`,
      getter: () => {
        return Number.isFinite(this.verticalPos) ? this.verticalPos : undefined;
      },
      setter: (value: string) => (this.moveModel(1, this.verticalPos, value))
    });

    propertyGroup.addNumber({
      name: $localize`Value`,
      getter: () => {
        return this.dimensionInfo?.size || null;
      },
      setter: (value: string) => (this.resizeDimension(value))
    });

    propertyGroup.addColorPicker({
      name: $localize`Color`,
      getter: () => {
        return this.dimensionInfo?.color || null;
      },
      setter: (value: string) => (this.setDimensionColor(value))
    });

    propertyGroup.addNumber({
      name: $localize`Thickness`,
      getter: () => {
        return this.wall?.thickness || null;
      },
      setter: (value: string) => (this.setWallThickness(value))
    });

    propertyGroup.addNumber({
      name: $localize`Height`,
      getter: () => {
        return this.wall?.height || null;
      },
      setter: (value: string) => (this.setWallHeight(value))
    });

    propertyGroup.addSelect({
      name: $localize`Baseline`,
      getter: () => {
        return this.wallBaseline || null;
      },
      setter: (baseline: string) => {
        this._updateFloorParameter(baseline, (floor, value, wall) => {
          floor.setWallBaseline(wall, value);
        });
      },
      variants: [
        {label: $localize`right`, value: '-1'},
        {label: $localize`middle`, value: '0'},
        {label: $localize`left`, value: '1'}
      ],
    });

    propertyGroup.addNumber({
      name: $localize`Height`,
      getter: () => {
        return this.room?.height || null;
      },
      setter: (value: string) => (this.setWallHeight(value)),
    });

    propertyGroup.addReadonly({
      name: $localize`Area`,
      getter: () => {
        return this.room?.area || null;
      },
      suffix: 'm²'
    });

    propertyGroup.addReadonly({
      name: $localize`Perimeter`,
      getter: () => {
        return this.room?.perimeter || null;
      },
      suffix: 'm'
    });

    propertyGroup.addButton({
      name: $localize`Material`,
      getter: (selected: Entity) => {
        let material = this.getMaterialProperty(selected);
        if (material) {
          return material.pipe(map(m => m.name));
        }
        return null;
      },
      button: {
        label: $localize`CHANGE`,
        click: () => (this.getMaterialProperty(this.selected).pipe(take(1)).subscribe(m => this.chooseMaterial(m)))
      }
    });

    return propertyGroup;
  }

  ngOnInit() {
    let group = this.intilizePropertyGroup();
    this.properties.splice(0, 0, group);
  }

  @Input() propertyEditor: EditorPropertyGroup;

  PropType = PropertyType;

  get properties() {
    return this.propertyEditor.items;
  }

  asGroup(property: EditorProperty) {
    if (property instanceof EditorPropertyGroup) {
      return property;
    }
  }

  modelPropsAndParams$: Observable<{props: EntityProperty[], params: ElasticParamView[]}>;
  elementsPropsAndParams$: Observable<{props: EntityProperty[], params: ElasticParamView[]}>;
  sizeInfo: SizeInfo = null;
  verticalPos = 0;
  alignItem = false;
  positionItem = false;
  wall?: {
    thickness: number;
    height: number;
    baseline: number;
  };
  room?: {
    area?: string;
    perimeter?: string;
    height: number;
  };
  changeSub: Subscription;
  dimensionInfo: {
    size: number;
    textSize: number;
    color: string | null;
  } = null;

  ds: WebDesigner;
  private _handler: ProjectHandler;
  @Input()
  set handler(value: ProjectHandler) {
    if (this.changeSub) {
      this.changeSub.unsubscribe();
      this.changeSub = undefined;
    }
    this.ds = undefined;
    if (value) {
      this.ds = value.ds;
      let onChange = merge(value.ds.modelChange, value.ds.selection.change,
        value.ds.serverSync, value.ds.serverError).pipe(
        auditTime(150)
      );
      this.changeSub = onChange.subscribe(() => this.updateProperties());
    }
    this.updateProperties();
    this._handler = value;
  }

  private _rootId?: string;
  private propertyUpdated$ = new BehaviorSubject(undefined);

  @Output() close = new EventEmitter();

  @Input()
  set rootId(value: string | undefined) {
    this._rootId = value;
    this.updateProperties();
  }

  get rootId() {
    return this._rootId;
  }

  get handler() {
    return this._handler;
  }

  ngOnDestroy() {
    this.propertyEditor.items.splice(0, 1);
    this.ds = undefined;
  }

  get selected() {
    let result: Entity;
    if (this.ds) {
      result = this.ds.selected;
      if (!result && this.rootId) {
        result = this.ds.root;
      }
    }
    return result;
  }

  get selectedItems() {
    let items = this.ds.selectedItems;
    if (items.length === 0 && this.rootId && this.ds.root) {
      items = [this.ds.root];
    }
    return items;
  }

  _selectedItems: Entity[];

  get type() {
    let s = this.selected;
    if (s) {
      if (s.data.floor) {
        return 2;
      }
      if (s.data.wall) {
        return 3;
      }
      if (s.data.room) {
        return 4;
      }
    }
    return this.ds.hasSelection ? 1 : 0;
  }

  get count() {
    return this.ds ? this.ds.selection.items.length : 0;
  }

  propertyLoader = this.catalogService.newPropertyLoader(5 * 60 * 1000);

  private getMaterial(
    catalog: number,
    name: string,
    defaultName = "Default"
  ) {
    return this.catalogService
      .findMaterial(catalog, name).pipe(
        catchError(_  => {
          let m = createMaterial(defaultName);
          return of(m);
        })
      );
  }

  private getMaterialProperty(entity: Entity): Observable<CatalogMaterial> | null {
    let material: Observable<CatalogMaterial> = null;
    if (entity.data.wall) {
      let wall = entity.data.wall;
      let floorData = entity.parent.data.floor;
      if (wall.material && wall.catalog) {
        material = this.getMaterial(wall.catalog, wall.material, "Wall");
      } else {
        material = this.getMaterial(
          floorData.wallCatalog,
          floorData.wallMaterial,
          "Wall"
        );
      }
    } else if (entity.data.room) {
      let room = entity.data.room;
      if (room.material) {
        material = this.getMaterial(room.catalog, room.material, "Room");
      } else {
        let floorData = entity.parent.data.floor;
        material = this.getMaterial(
          floorData.floorCatalog,
          floorData.floorMaterial,
          "Room"
        );
      }
    } else if (entity.data.ceiling) {
      let ceiling = entity.data.ceiling;
      if (ceiling.material) {
        material = this.getMaterial(ceiling.catalog, ceiling.material, "Ceiling");
      } else {
        let floorData = entity.parent.data.floor;
        material = this.getMaterial(
          floorData.ceilingCatalog || floorData.floorCatalog,
          floorData.ceilingMaterial || floorData.floorMaterial,
          "Ceiling"
        );
      }
    }
    return material;
  }

  public materialCache = new CatalogMaterialCache(this.catalogService);

  private loadMaterials(props: EntityProperty[]) {
    let materials = ModelHandler.gatherMaterialsFromProperties(props);
    this.materialCache.add(materials).subscribe(_ => this.cd.markForCheck());
  }

  findMaterial(property: EntityProperty, variant?: PropertyVariant) {
    return this.materialCache.get(property.findMaterial(variant));
  }

  findValueMaterial(property: EntityProperty, value: number) {
    let variant = property.find(value);
    return variant && this.materialCache.get(property.findMaterial(variant));
  }

  private updateProperties() {
    this.propertyUpdated$.next(undefined);
    this.sizeInfo = null;
    this.wall = undefined;
    this.room = undefined;
    this.alignItem = false;
    this.positionItem = false;
    this.verticalPos = undefined;
    this.dimensionInfo = null;
    if (this.ds) {
      this.updateSizeInfo();
      let selected = this.selected;
      let selection = this.ds.selection.items;
      let getter = <T>(prop: (e: Entity) => T) => {
        let first = prop(selection[0]);
        for (let k = 1; k < selection.length; ++k) {
          let value = prop(selection[k]);
          if (typeof first === 'number') {
            if (typeof value !== 'number' || !glMatrix.equals(first, value, 0.1)) {
              return undefined;
            }
          } else if (value !== first) {
            return undefined;
          }
        }
        return first;
      }

      if (selected) {
        if (selected.data) {
          this.updateRoomElementProperties(selected);
        }
        if (selected.data.drawing) {
          let drawing = Element.create(selected.data.drawing);
          if (drawing instanceof Size) {
            this.dimensionInfo = {
              color: drawing.color || '#000000',
              size: drawing.value,
              textSize: 1
            }
          }
        }
      }

      this.verticalPos = getter(e => e && !e.isContainerItem && e.data.model && e.toGlobal(e.sizeBox.min)[1]);
      if (Number.isFinite(this.verticalPos)) {
        this.verticalPos = roundFloat(this.verticalPos);
      } else {
        this.verticalPos = undefined;
      }
      // change ref to force check the property-editor-table-row component
      this._selectedItems =  [...this.selectedItems];
    }
    this.cd.markForCheck();
  }

  private updateSizeInfo() {
    for (let e of this.selectedItems) {
      if (e.data) {
        let d = e.data;
        if (d.wall || d.floor || d.ceiling || d.room || d.drawing) {
          this.sizeInfo = null;
          break;
        }
      }
      let curInfo = e.getSizeInfo();
      if (e.data.propInfo && e.data.propInfo.size) {
        let size = e.data.propInfo.size;
        if (size['#width']) {
          curInfo.xe = false;
        }
        if (size['#height']) {
          curInfo.ye = false;
        }
        if (size['#depth']) {
          curInfo.ze = false;
        }
      }
      this.sizeInfo = this.sizeInfo || curInfo;
      if (!glMatrix.equalsd(curInfo.x, this.sizeInfo.x)) {
        this.sizeInfo.x = undefined;
      }
      if (!glMatrix.equalsd(curInfo.y, this.sizeInfo.y)) {
        this.sizeInfo.y = undefined;
      }
      if (!glMatrix.equalsd(curInfo.z, this.sizeInfo.z)) {
        this.sizeInfo.z = undefined;
      }
      this.sizeInfo.xe = this.sizeInfo.xe && curInfo.xe;
      this.sizeInfo.ye = this.sizeInfo.ye && curInfo.ye;
      this.sizeInfo.ze = this.sizeInfo.ze && curInfo.ze;
      if (this.sizeInfo.position !== curInfo.position) {
        this.sizeInfo.position = pb.Elastic.Position.None;
      }
    }
  }

  apply(name: string, mapper: (e: Entity) => BuilderApplyInfo) {
    let idMapper = (e: Entity) => ({uid: e, ...mapper(e)});
    this.ds.applyBatch(name, this.selectedItems.map(idMapper));
  }

  private updateRoomElementProperties(entity: Entity) {
    if (!entity.parent) return;
    if (entity.data.wall) {
      let wall = entity.data.wall;
      let floorData = entity.parent.data.floor;
      this.wall = {
        thickness: wall.thickness || floorData.wallThickness,
        height: wall.height || floorData.wallHeight,
        baseline: wall.baseline || 0
      };
    }
    if (entity.data.room) {
      let height = entity.parent.data.floor.wallHeight;
      let room = entity.data.room;
      let contour = new Contour();
      contour.load(room.contour);
      let area = this.ds.floatToStr(contourArea(contour) * 1e-6);
      let perimeter = this.ds.floatToStr(contour.length * 1e-3);
      this.room = { height, area, perimeter };
    } else if (entity.data.ceiling) {
      let height = entity.parent.data.floor.wallHeight;
      this.room = { height };
    }
  }

  resizeModel(axis: string, value: string, param?: ElasticParamView) {
    let newSize = {};
    let min = param ? -MathCalculator.MAX_RANGE : 1;
    let floatValue = MathCalculator.calculateRange(value, min);
    if (this.sizeInfo) {
      // update sizeInfo to display computed value
      if (axis === '#width') {
        this.sizeInfo.x = floatValue;
      } else if (axis === '#height') {
        this.sizeInfo.y = floatValue;
      } else if (axis === '#depth') {
        this.sizeInfo.z = floatValue;
      } else {
        this.sizeInfo[axis] = floatValue;
      }
      this.cd.markForCheck();
    }
    newSize[axis] = floatValue;
    let entities = param ? param.entitites : this.selectedItems;
    let items = entities.map(e => ({uid: e, size: newSize}));
    this.ds.applyBatch("Resize model", items).then(_ => {
      // update properties anyway because
      // if model won't change on server due to limits
      // we should display original values
      this.updateProperties();
    });
  }

  moveModel(axis: number, oldValue: number, value: string | number) {
    let floatValue = MathCalculator.calculateRange(value);
    let dir = vec3.create();
    dir[axis] = floatValue - oldValue;
    let items = this.selectedItems.map(e => {
      let localDir = e.NtoLocal(dir);
      e.translate(localDir);
      return {uid: e, matrix: e.matrix};
    });
    this.ds.applyBatch("Move model", items);
  }

  private _updateFloorParameter(
    newValue: string,
    apply: (floor: FloorBuilder, value: number, wall?: number) => any,
    min?: number,
    max?: number
  ) {
    let floorElem = this.selected;
    let floorRoot = floorElem.findParent(p => !!p.data.floor);
    if (!floorRoot) {
      throw new Error("Selected element is outside floorplan");
    }
    let floor = new FloorBuilder(floorRoot);
    floor.init();
    let value = MathCalculator.calculate(newValue);
    if (min) {
      value = Math.max(value, min);
    }
    if (max) {
      value = Math.min(value, max);
    }
    let wallId = floorElem.data.wall ? floorElem.data.wall.id : undefined;
    if (value !== undefined && apply(floor, value, wallId) !== false) {
      floor.updateMap(floor.map);
      let command = floor.buildFloor();
      this.ds.apply("Update wall parameter", command);
    }
  }

  setWallThickness(newValue: string) {
    this._updateFloorParameter(newValue, (floor, value, wall) => {
      floor.setWallThickness(wall, value);
    }, 1, 2000);
  }

  setWallHeight(newValue: string) {
    this._updateFloorParameter(newValue, (floor, value, wall) => {
      if (wall) {
        floor.setWallHeight(wall, value);
      } else {
        floor.wallHeight = value;
      }
    }, 1, 10000);
  }

  get wallBaseline() {
    return this.wall?.baseline.toString();
  }


  selectMaterial(material: CatalogMaterial) {
    let dialogRef = this.dialogs.open(MaterialSelectorComponent, {
      width: "50%",
      height: "60%",
      data: material
    });
    let selector = dialogRef.componentInstance;
    if (material) {
      selector.setCatalog(material.catalogId);
    }
    selector.viewMode = MaterialViewMode.Grid;
    selector.displayCatalogs = true;
    selector.select.subscribe(_ => dialogRef.close());
    return selector.select;
  }

  changeFloor(floor: Entity, change: (floor: FloorBuilder) => void) {
    let builder = new FloorBuilder(floor);
    builder.init();
    change(builder);
    builder.updateMap(builder.map);
    let command = builder.buildFloor();
    this.ds.apply("Change wall material", command);
  }

  chooseMaterial(material: CatalogMaterial) {
    this.selectMaterial(material).subscribe((mat: CatalogMaterial) => {
      let entity = this.selected;
      if (entity.data.wall) {
        this.changeFloor(entity.parent, f =>
          f.changeWallMaterial(entity.data.wall.id, mat.name, mat.catalogId)
        );
      }
      if (entity.data.room) {
        this.changeFloor(entity.parent, f =>
          f.changeRoomMaterial(entity.data.room.id, mat.name, mat.catalogId)
        );
      }
      if (entity.data.ceiling) {
        this.changeFloor(entity.parent, f =>
          f.changeCeilingMaterial(
            entity.data.ceiling.id,
            mat.name,
            mat.catalogId
          )
        );
      }
    });
  }

  propertyValueChanged(p: Property, event: MatSelectChange, e?: Entity) {
    let items = e ? [e] : this.ds.selectedItems;
    if (event.value) {
      ModelHandler.applyProperty(this.ds, p.id, event.value,
        items, this.propertyLoader).subscribe();
    }
  }

  renameSelection(newName: string) {
    this.apply("Rename", e => ({ name: newName }));
  }

  clearSelection() {
    this.ds.selection.clear();
  }

  private editSize(editor: (size: Size) => any) {
    let dim = this.ds.selected;
    let size = Element.create(dim.data.drawing);
    if (size instanceof Size) {
      if (editor(size) !== false) {
        this.ds.apply('size', { uid: dim, data: { drawing: JSON.stringify(size.save()) } });
      }
    }
  }

  resizeDimension(value: string | number) {
    this.editSize(size => size.value = MathCalculator.calculate(value));
  }

  setDimensionColor(value?: string) {
    if (value) {
      this.editSize(size => size.color = value);
    }
  }

  fieldAppearance(writeable: boolean) {
    return writeable ? 'outline' : 'fill';
  }

  paramTrackBy = (_: number, item: ElasticParamView) => item.name;

  propertyTrackBy = (_: number, item: EntityProperty) => item.id;
}
